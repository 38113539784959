<template>
    <button :disabled="disabled" class="btn font-poppins btn-light ordinary-btn text-custom w-100">
        <slot></slot>
    </button>
</template>
<script>
export default {
    props: {
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>
<style>
.ordinary-btn {
    font-weight: 800;
    padding: .6rem 0;
    background-color: #FFFFFF;
    color: #FFC107 !important;
}
</style>