import axios from 'axios';
import authHeader from './auth-header';
// const API_URL = 'http://localhost:8000/api/';
const API_URL = 'https://backend.luckymen2d.com/api/';

class UserService {


  get2DLive() {
    return axios.get('https://live.luckymen2d.com/api.php');
  }

  getUserInfo() {
    return axios.get(API_URL + 'customer/info', { headers: authHeader() });
  }

  applyTopUp(data) {
    return axios.post(API_URL + 'deposit/submit',data, { headers:authHeader() })
  }

  applyWithdraw(data) {
    return axios.post(API_URL + 'withdraw/submit',data, { headers:authHeader() })
  }

  //2D
  get2DScheduleList() {
    return axios.get(API_URL + 'twod_section');
  }

  get2DScheduleDetail(id) {
    return axios.get(API_URL + 'twod_section/'+id)
  }

  bet2DSchedule(id,data) {
    return axios.post(API_URL + 'twod_section/'+id+'/bet', data, { headers:authHeader() })
  }

  getUser2DBettingHistory() {
    return axios.get(API_URL + 'twod/betting_log', {headers:authHeader()})
  }

  getUser2DBettingHistoryDetail(date) {
    return axios.get(API_URL + 'twod/betting_log/' + date , {headers:authHeader()})
  }


  get2DWinnerList() {
    return axios.get(API_URL + 'twod/winners', { headers: authHeader() });
  }

   get3DWinnerList() {
    return axios.get(API_URL + 'threed/winners', { headers: authHeader() });
  }

  //3D

  get3DLatestResult() {
    return axios.get(API_URL + 'threed/result/latest', { headers: authHeader() });
  }

  bet3DSchedule(id,data) {
    return axios.post(API_URL + 'threed_section/'+id+'/bet', data, { headers:authHeader() })
  }

  get3DScheduleList() {
    return axios.get(API_URL + 'threed_section');
  }

  get3DScheduleHistory() {
    return axios.get(API_URL + 'threed_section/history');
  }

  get3DScheduleDetail(data) {
    return axios.get(API_URL + 'threed_section/'+data);
  }

  getUser3DBettingHistory() {
    return axios.get(API_URL + 'threed/betting_log', {headers:authHeader()})
  }

  getUser3DBettingHistoryDetail(date) {
    return axios.get(API_URL + 'threed/betting_log/'+date, {headers:authHeader()})
  }
  

  getTransactionHistory() {
    return axios.get(API_URL + 'transaction', { headers: authHeader() });
  }

  getTransactionDetail(id) {
    return axios.get(API_URL + 'transaction/' + id, { headers: authHeader() });
  }

  getServices() {
    return axios.get(API_URL + 'service');
  }

  getPaymentMethods() {
    return axios.get(API_URL + 'payment-method');
    
  }
  

  getNotifications() {
    return axios.get(API_URL + 'notification' , {headers: authHeader()});
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }

  getAgents() {
    return axios.get(API_URL + 'agent-info/agent');
  }

  getClosingDays() {
    return axios.get(API_URL + 'closing-day');
  }

  getHomeBanner() {
    return axios.get(API_URL + 'slide/home');
  }

  updateProfile(data) {
    return axios.post(API_URL + 'profile/update',data,{headers:authHeader()})
  }

}

export default new UserService();