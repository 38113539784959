<template>
    <div class="p-4">
        <form @submit="handleLogin()" class="justify-content-between h-100">
            <h1 class="title mb-3 text-center">အကောင့်ဝင်ရန်</h1>
            <!-- <p class="subtitle mb-4">အကောင့်ဝင်ရန် သင့်ဖုန်းနံပါတ်ရိုက်ထည့်ပါ</p> -->

            <div>
                <FormGroup v-model="phone" :errors="errors.phone" label="ဖုန်းနံပါတ်" type="number" class="mb-4">
                </FormGroup>
                <FormGroup v-model="password" :errors="errors.password" label="စကားဝှက်" type="password" class="">
                </FormGroup>
            </div>

            <PrimaryButton type="submit" class="login-btn text-dark">Log in</PrimaryButton>

            <div class="mt-5">
                <p class="text-center text-dark text-decoration-underline sub-lable font-noto mb-1">စကားဝှက်မေ့နေလား?</p>
                <router-link :to="{ name: 'register' }">
                    <SecondaryButton class="">အကောင့်အသစ်ဖွင့်ရန်</SecondaryButton>
                </router-link>

            </div>

        </form>
    </div>
</template>
<script>
export default {
    data() {
        return {
            phone: "",
            password: "",
            errors: {
                phone: []
            }
        }
    },
    created() {
        if (this.$store.state.auth.status.loggedIn) {
            this.$router.push({ name: 'home' })
        }
    },
    methods: {
        handleLogin() {
            this.$store.dispatch('loading')
            console.log(this.$store.state.index.loading)
            event.preventDefault()
            this.$store.dispatch('auth/login', {
                phone: this.phone,
                password: this.password
            }).then((data) => {
                this.$store.dispatch('loadingComplete')
                if (data.success) {
                    this.$store.dispatch('showToast', 'အကောင့်ဝင်ခြင်း အောင်မြင်ပါသည်။')
                    this.$router.push({ name: 'home' })
                }
            }).catch(({ response }) => {
                if (response.status == 403) {
                    this.$store.dispatch('showError', response.data.message)
                }
            })
        }
    }
}
</script>
<style></style>
