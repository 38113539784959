<template>
    <div class="two-d-section">
        <div style="position: fixed;top: 0;text-align: center;height: 50px;max-width: 250px;left: 0;right: 0;"
            class="d-flex align-items-center mx-auto justify-content-center">
            <h1 class="fw-600 mb-0 fs-16 text-dark">{{
                $helper.formattedTime(selectedSection.opening_time) }} <span class="fw-400 fs-12">({{
        selectedSection.multiply }}ဆ)</span> </h1>
        </div>
        <div class="content-wrapper py-3 bg-white">
            <div class="d-flex justify-content-center w-100 text-danger">
                <p class="fw-bold fs-12 me-3 mb-0">ထီပိတ်ချိန်</p>
                <h1 class="fw-600 fs-16"><span>{{ countDown ?? '0 : 0 : 0' }} </span></h1>

            </div>

            <div class="content px-2">

                <div class="numbers d-flex flex-wrap">
                    <div v-for="(value, index) in numbers" @click="toggleNumber(value)" :key="index" class="number p-2">
                        <div :class="{ 'full': value.percent > 99.99, 'choosed': selectedNumbers.find(n => n.id == value.id) }"
                            class="fw-400 digit fs-16">
                            <span>{{ value.number }}</span>
                            <div class="digit-process-wrapper rounded">
                                <div style="height: 3px;" :style="{ width: value.percent + '%' }"
                                    :class="{ 'bg-danger': value.percent == 100, 'bg-warning': value.percent >= 50 && value.percent < 100, 'bg-success': value.percent < 50 }">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bet-amount-box py-3 px-3 bg-custom2">
            <div class="d-flex align-items-center mb-2">
                <span class="fw-400 fs-12 me-1">လက်ကျန်ငွေ</span>
                <span class="fw-600 fs-16 text-dark">{{ $helper.formattedNumber(currentUser.balance) }} Ks</span>

                <span @click="showColorDescriptionModal = true"
                    class="ms-auto fw-500 fs-14 text-decoration-underline">အရောင်သတ်မှတ်ချက်</span>
            </div>
            <div class="d-flex align-items-center pb-2">
                <div class="col-3 pe-1">
                    <OutlineButton @click="round()" class="py-2">
                        <!-- <font-awesome-icon icon="fa-solid fa-retweet" size="sm" /> -->
                        <span class="fw-600 fs-14 ms-2">R မည်</span>
                    </OutlineButton>
                </div>
                <div class="col-5 pe-1">
                    <OutlineButton @click="showQuickChooseModal = true" class="py-2">
                        <!-- <font-awesome-icon icon="fa-solid fa-retweet" size="sm" /> -->
                        <span class="fw-600 fs-14 ms-2">အမြန်ရွေးမည်</span>
                    </OutlineButton>
                </div>
                <div class="col-4">
                    <OutlineButton @click="deleteAll()" class="py-2">
                        <font-awesome-icon icon="fa-solid fa-trash" class="text-danger" size="sm" />
                        <span class="fw-600 fs-14 ms-2">ဖျက်မည်</span>
                    </OutlineButton>
                </div>



            </div>
            <div class="d-flex mt-2">
                <div class="col-7  rounded-3 d-flex align-items-center" style="background-color: #FFF0A4;">
                    <!-- <span class="fw-400 text-custom fs-12 col-5 text-center">ထိုးမည့်ပမာဏ</span> -->

                    <PrimaryButton class="text-dark" @click="decreaseBetAmount">-</PrimaryButton>
                    <input style="width: 120px;" v-model="betAmount"
                        class="border text-center border-2 border-dark text-dark rounded mx-2 px-2 py-1" type="number">
                    <PrimaryButton class="text-dark" @click="increaseBetAmount">+</PrimaryButton>

                </div>
                <div class="col-5 ps-2">
                    <PrimaryButton class="text-dark" @click="startBetting()">ထိုးမည်</PrimaryButton>
                </div>
            </div>
        </div>

        <ModalBox :show="showQuickChooseModal"
            @close="showQuickChooseModal = false; showRoundModal = false; showRoundPModal = false">
            <template v-slot:header>အမြန်ရွေးရန််</template>
            <template v-slot:body>
                <div class="mt-1">
                    <div v-for="(group, index) in quicks" :key="index" class="mb-2">
                        <h1 class="fw-600 fs-16">{{ group.name }}</h1>
                        <div class="d-flex flex-wrap">
                            <div @click="quickChoose(item.action)" class="mb-2"
                                :class="{ 'pe-1': group.col != 2 ? (i % 5 != 0 || i == 0) : ((i + 1) % 3 != 0 || i == 0), 'col-2': group.col != 2, 'col-4': group.col == 2 }"
                                v-for="(item, i) in group.items" :key="i">
                                <PrimaryButton>{{ item.name }}</PrimaryButton>
                            </div>

                        </div>
                    </div>
                </div>
            </template>
        </ModalBox>

        <ModalBox :show="showColorDescriptionModal" @close="showColorDescriptionModal = false">
            <template v-slot:header>အရောင်သတ်မှတ်ချက်</template>
            <template v-slot:body>
                <div class="row align-items-center">
                    <div class="col-6 d-flex align-items-center mb-2">
                        <div style="height: 20px;width: 20px;" class="bg-success">
                        </div>
                        <span class="fw-400 fs-12 ms-2">၅၀% အောက်</span>
                    </div>
                    <div class="col-6 d-flex align-items-center mb-2">
                        <div style="height: 20px;width: 20px;" class="bg-warning">
                        </div>
                        <span class="fw-400 fs-12 ms-2">၅၀% မှ ၉၉% ကြား</span>
                    </div>
                    <div class="col-6 d-flex align-items-center mb-2">
                        <div style="height: 20px;width: 20px;" class="bg-danger">
                        </div>
                        <span class="fw-400 fs-12 ms-2">ထိုးငွေပြည့်သွားပါပြီ</span>
                    </div>
                </div>

            </template>
        </ModalBox>

        <ModalBox :show="showRoundModal" @close="showRoundModal = false">
            <template v-slot:header>အခွေ</template>
            <template v-slot:body>

                <div>
                    <div>
                        <FormGroup v-model="roundNumbers" label="ခွေရန်ဂဏန်းထည့်ပါ" placeholder="123"></FormGroup>
                    </div>
                    <PrimaryButton @click="addRoundNumbers">ထည့်မည်</PrimaryButton>
                </div>

            </template>
        </ModalBox>

        <ModalBox :show="showRoundPModal" @close="showRoundPModal = false">
            <template v-slot:header>အခွေ(အပူးမပါ)</template>
            <template v-slot:body>

                <div>
                    <div>
                        <FormGroup v-model="roundPNumbers" label="ခွေရန်ဂဏန်းထည့်ပါ" placeholder="123"></FormGroup>
                    </div>
                    <PrimaryButton @click="addRoundPNumbers">ထည့်မည်</PrimaryButton>
                </div>

            </template>
        </ModalBox>

        <Transition name="slide-full" appear>
            <div v-if="showModalBox"
                style="position: fixed;top:0;z-index: 1000;height: 100vh;overflow-y: auto;padding-bottom: 110px;width: 100vw;max-width: 450px;"
                class="d-flex flex-column bg-white w-100 h-100">
                <div class="p-3">
                    <div style="position: relative;">
                        <font-awesome-icon @click="showModalBox = false" size="lg" :icon="['fas', 'fa-times']"
                            style="position: absolute;"></font-awesome-icon>
                        <h1 class="fw-600 mb-4 fs-14 text-center">ထိုးမည့်ဂဏန်းများ</h1>

                    </div>
                    <div class="row mb-2">
                        <div class="col-3 text-center">
                            <span class="fw-600 fs-12">နံပါတ်</span>
                        </div>
                        <div class="col-3 text-center">
                            <span class="fw-600 fs-12">ဆ</span>
                        </div>
                        <div class="col-4 text-center">
                            <span class="fw-600 fs-12">ကြေး</span>
                        </div>
                    </div>
                    <div class="horizontal-line mb-2"></div>

                    <div class="row align-items-center pb-3" v-for="(number, index) in selectedNumbers" :key="index">
                        <div class="col-3">
                            <form-group class="fw-400 fs-14" type="number" :readOnly="true" :modelValue="number.number"
                                size="sm"></form-group>
                        </div>
                        <div class="col-3">
                            <form-group class="fw-400 fs-14" :readOnly="true" :modelValue="selectedSection.multiply"
                                size="sm"></form-group>
                        </div>
                        <div class="col-4">
                            <form-group class="fw-400 fs-14" :hasError="errors[number.number]" type="number" v-model="number.amount" size="sm"></form-group>
                        </div>
                        <div class="col-2 d-flex align-items-center">
                            <span @click="removeNumber(number)" style="width:30px;aspect-ratio: 1/1;"
                                class="d-flex align-items-center justify-content-center bg-danger text-white rounded-circle me-2">
                                <font-awesome-icon icon="fa-solid fa-trash" size="xs" />
                            </span>
                        </div>
                        <div class="col-10 text-end ms-auto" style="line-height: 1;">
                            <small class="text-danger fs-12 fw-400">{{errors[number.number]}}</small>
                        </div>
                        <div class="col-2"></div>
                    </div>
                </div>

                <div class="mt-auto" style="position: fixed;bottom:0;width: 100vw;max-width: 450px;height: 105px;">
                    <div class="p-2 ">

                        <div class="bg-custom2 rounded p-2">
                            <div class="d-flex align-items-center mb-2">
                                <span class="fw-400 fs-12 mx-2">စုစုပေါင်းထိုးငွေ</span>
                                <span class="fw-600 fs-16 text-dark ms-auto">{{ $helper.formattedNumber(totalBetAmount) }}
                                    Ks</span>

                            </div>
                            <PrimaryButton @click="bet()" class="fw-700 fs-14">
                                အတည်ပြုမည်</PrimaryButton>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>

    </div>
</template>
<script>
import ModalBox from '@/components/ModalComponent.vue';
import OutlineButton from '@/components/buttons/OutlineButton.vue';
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import userService from '@/services/user.service';
import moment from 'moment';
export default {
    components: {
        ModalBox,
        PrimaryButton,
        OutlineButton
    },
    data() {
        return {
            roundNumbers: null, roundPNumbers: null,
            showQuickChooseModal: false,
            selectedSection: {},
            showModalBox: false,
            showRoundModal: false,
            showRoundPPModal: false,
            showColorDescriptionModal: false,
            numbers: [
            ],
            quicks: [
                {
                    name: 'အကွက်',
                    items: [
                        {
                            name: 'ကြီး',
                            action: 'Big'
                        },
                        {
                            name: 'ငယ်',
                            action: 'Small'
                        },
                        {
                            name: 'မ',
                            action: 'Odd'
                        },
                        {
                            name: 'စုံ',
                            action: 'Even'
                        },
                        {
                            name: 'စုံစုံ',
                            action: 'SS'
                        },
                        {
                            name: 'စုံမ',
                            action: 'SM'
                        },
                        {
                            name: 'မစုံ',
                            action: 'MS'
                        },
                        {
                            name: 'မမ',
                            action: 'MM'
                        },
                        {
                            name: 'အပူး',
                            action: 'P'
                        }
                    ]
                },
                {
                    name: '၁လုံးပါ',
                    items: [
                        {
                            name: '0',
                            action: '0+'
                        },
                        {
                            name: '1',
                            action: '1+'
                        },
                        {
                            name: '2',
                            action: '2+'
                        },
                        {
                            name: '3',
                            action: '3+'
                        },
                        {
                            name: '4',
                            action: '4+'
                        },
                        {
                            name: '5',
                            action: '5+'
                        },
                        {
                            name: '6',
                            action: '6+'
                        },
                        {
                            name: '7',
                            action: '7+'
                        },
                        {
                            name: '8',
                            action: '8+'
                        },
                        {
                            name: '9',
                            action: '9+'
                        }
                    ]
                },
                {
                    name: 'ထိပ်',
                    items: [
                        {
                            name: '0',
                            action: '0*'
                        },
                        {
                            name: '1',
                            action: '1*'
                        },
                        {
                            name: '2',
                            action: '2*'
                        },
                        {
                            name: '3',
                            action: '3*'
                        },
                        {
                            name: '4',
                            action: '4*'
                        },
                        {
                            name: '5',
                            action: '5*'
                        },
                        {
                            name: '6',
                            action: '6*'
                        },
                        {
                            name: '7',
                            action: '7*'
                        },
                        {
                            name: '8',
                            action: '8*'
                        },
                        {
                            name: '9',
                            action: '9*'
                        }
                    ]
                },
                {
                    name: 'နောက်',
                    items: [
                        {
                            name: '0',
                            action: '*0'
                        },
                        {
                            name: '1',
                            action: '*1'
                        },
                        {
                            name: '2',
                            action: '*2'
                        },
                        {
                            name: '3',
                            action: '*3'
                        },
                        {
                            name: '4',
                            action: '*4'
                        },
                        {
                            name: '5',
                            action: '*5'
                        },
                        {
                            name: '6',
                            action: '*6'
                        },
                        {
                            name: '7',
                            action: '*7'
                        },
                        {
                            name: '8',
                            action: '*8'
                        },
                        {
                            name: '9',
                            action: '*9'
                        }
                    ]
                },
                {
                    name: 'ဘရိတ်',
                    items: [
                        {
                            name: '0/B',
                            action: '0/B'
                        },
                        {
                            name: '1/B',
                            action: '1/B'
                        },
                        {
                            name: '2/B',
                            action: '2/B'
                        },
                        {
                            name: '3/B',
                            action: '3/B'
                        },
                        {
                            name: '4/B',
                            action: '4/B'
                        },
                        {
                            name: '5/B',
                            action: '5/B'
                        },
                        {
                            name: '6/B',
                            action: '6/B'
                        },
                        {
                            name: '7/B',
                            action: '7/B'
                        },
                        {
                            name: '8/B',
                            action: '8/B'
                        },
                        {
                            name: '9/B',
                            action: '9/B'
                        }
                    ]
                },
                {
                    name: 'အခြား',
                    col: 2,
                    items: [
                        {
                            name: 'အခွေ',
                            action: 'Round'
                        },
                        {
                            name: 'အခွေ(အပူးမပါ)',
                            action: 'RoundP'
                        },
                        {
                            name: 'နက္ခတ်',
                            action: 'K'
                        },
                        {
                            name: 'ညီကို',
                            action: 'NK'
                        },
                        {
                            name: 'ပါဝါ',
                            action: 'W'
                        },
                        {
                            name: '00-19',
                            action: '00-19'
                        },
                        {
                            name: '20-39',
                            action: '20-39'
                        },
                        {
                            name: '40-59',
                            action: '40-59'
                        },
                        {
                            name: '60-79',
                            action: '60-79'
                        },
                        {
                            name: '80-99',
                            action: '80-99'
                        }
                    ]
                }
            ],
            betAmount: 0,
            selectedNumbers: [],
            logined: this.$store.state.auth.status.loggedIn,
            currentUser: this.$store.state.auth.user,
            countDown: null,
            errors:{}
        }
    },
    mounted() {
        let selectedSection = this.$route.params.section
        if (selectedSection) {
            userService.get2DScheduleDetail(selectedSection).then(({ data }) => {
                this.numbers = data.data.numbers
                this.selectedSection = data.data
                this.startCountDown()
            })
        } else {
            this.$router.push({ name: '2d' })
        }
    },
    computed: {
        totalBetAmount() {
            return this.selectedNumbers.reduce((total, value) => {
                return total + parseInt(value.amount);
            }, 0);
        }
    },
    methods: {
        addRoundNumbers() {
            if (!this.roundNumbers || this.roundNumbers.toString().length < 3) {
                this.$store.dispatch('showError', 'အနည်းဆုံး ဂဏန်း ၃ လုံး ထည့်ပါ။')
            } else {
                let numbers = this.$2d.getNumbers(this.roundNumbers + 'AP', 0)
                numbers.forEach(n => {
                    let index = this.numbers.findIndex(sn => sn.number == n.number)
                    this.toggleNumber(this.numbers[index], false)
                })
                this.showRoundModal = false
                this.showQuickChooseModal = false
                this.roundNumbers = null
            }
        },
        addRoundPNumbers() {
            if (!this.roundPNumbers || this.roundPNumbers.toString().length < 3) {
                this.$store.dispatch('showError', 'အနည်းဆုံး ဂဏန်း ၃ လုံး ထည့်ပါ။')
            } else {
                let numbers = this.$2d.getNumbers(this.roundPNumbers + 'APP', 0)
                // if (numbers) {
                //     this.$store.dispatch('showError', 'ဂဏန်းမှား။')
                //     return false;
                // }
                numbers.forEach(n => {
                    let index = this.numbers.findIndex(sn => sn.number == n.number)
                    this.toggleNumber(this.numbers[index], false)
                })
                this.showRoundPModal = false
                this.showQuickChooseModal = false
                this.roundPNumbers = null

            }
        },
        increaseBetAmount() {
            this.betAmount += 100;
        },
        decreaseBetAmount() {
            if (this.betAmount > 0) {
                this.betAmount -= 100;
            }
        },
        deleteAll() {
            this.selectedNumbers = []
        },
        startCountDown() {
            var that = this;
            let coundDownInterval = setInterval(function () {
                var eventTime = moment(new Date(moment().format("YYYY-MM-DD") + ' ' + that.selectedSection.end_time)); // Timestamp - Sun, 21 Apr 2013 13:00:00 GMT
                var currentTime = moment(new Date());
                var diffTime = eventTime - currentTime;
                if (diffTime < 1) {
                    that.countDown = "0 : 0 : 0";
                    clearInterval(coundDownInterval)
                } else {
                    var duration = moment.duration(diffTime, 'milliseconds');
                    that.countDown = (duration.hours().toString().padStart(2, '0') + " : " + duration.minutes().toString().padStart(2, '0') + " : " + duration.seconds().toString().padStart(2, '0'))
                }
            }, 1000);
        },
        quickChoose(action) {
            if (action == 'Round') {
                this.showRoundModal = true
                this.showQuickChooseModal = false

            } else if (action == 'RoundP') {
                this.showRoundPModal = true
                this.showQuickChooseModal = false

            }
            else {
                let numbers = this.$2d.getNumbers(action, 0)
                numbers.forEach(n => {
                    let index = this.numbers.findIndex(sn => sn.number == n.number)
                    this.toggleNumber(this.numbers[index], false)
                })
                this.showQuickChooseModal = false
            }
        },
        toggleNumber(number, toggle = true) {
            if (number.percent > 99.99) {
                return;
            }

            let index = this.selectedNumbers.findIndex(n => {
                return n.id == number.id
            });

            if (index != -1) {
                if (toggle) {
                    this.selectedNumbers.splice(index, 1)
                }
            } else {
                this.selectedNumbers.push({
                    id: number.id,
                    number: number.number,
                    amount: this.betAmount
                })
            }
        },
        removeNumber(number) {
            let index = this.selectedNumbers.findIndex(n => {
                return n.id == number.id
            });
            if (index != -1) {
                this.selectedNumbers.splice(index, 1)
            }
        },
        round() {
            this.selectedNumbers.forEach(value => {
                let number = value.number;
                let round_number = this.numbers.find(n => {
                    return n.number == Number(String(number).split('').reverse().join(''))
                })

                let round_number_index = this.selectedNumbers.findIndex(n => {
                    return n.number == round_number.number
                });

                if (round_number_index == -1) {
                    this.selectedNumbers.push({
                        id: round_number.id,
                        number: round_number.number,
                        amount: this.betAmount
                    })
                }
            })
        },
        startBetting() {
            if (this.selectedNumbers.length <= 0) {
                this.$store.dispatch('showError', 'နံပါတ်ရွေးပါ')
                return
            }

            if (this.betAmount <= 0) {
                this.$store.dispatch('showError', 'ပမာဏထည့်ပါ')
                return
            }

            this.selectedNumbers.forEach((value, index) => {
                this.selectedNumbers[index].amount = this.betAmount
            })
            this.showModalBox = true
        },
        bet() {
            this.$store.dispatch('loading')
            userService.bet2DSchedule(this.selectedSection.id, {
                bet_data: this.selectedNumbers
            }).then(({ data }) => {
                this.$store.dispatch('loadingComplete')
                if (data.success) {
                    this.$store.dispatch('showToast', data.message)
                    this.$router.push({ name: '2d' })
                }
            }).catch(({ response }) => {
                if (response.status == 403) {
                    if (response.data.message) {
                        this.$store.dispatch('showError', response.data.message)
                    } 

                    if (response.data.errors) {
                        this.errors = response.data.errors
                    }
                }
            })
        }
    },
}

</script>
<style>
/* .two-d-section .banner {
    margin-top: -1px;
    color: #fff;
    background-color: #FFC107;
    height: 9vh;
} */

.content-wrapper {
    margin-bottom: 160px;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
}

.two-d-section .number {
    width: 20%;
}

.number .digit {
    border: 2px solid #FFC107 !important;
    color: #000;
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 1rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 1rem;
}

.number .digit-process-wrapper {
    position: absolute;
    width: 65%;
    height: 3px;
    background-color: #d1ffbd;
    bottom: .7rem;
}

.number .icon {
    bottom: 5px;
    font-size: .4rem;
}

.number .full {
    background-color: transparent !important;
    color: #9e9e9e !important;
    border: 1px solid #FFF0A4;

}

.number .choosed {
    background-color: #FFC107 !important;
    color: #000 !important;
}

.two-d-section .bet-amount-box {
    position: fixed;
    width: 100%;
    max-width: 450px;
    bottom: 0px;
    border-top: 1px solid #FFF0A4;
    height: 160px !important;
    z-index: 1;
}

.bet-amount-box input {
    border: none;
    outline: none;
    background-color: transparent;
}

.slide-full-enter-active {
    transition: all 0.8s ease-out;
}

.slide-full-leave-active {
    transition: all 0.8s ease-in;
}

.slide-full-enter-from,
.slide-full-leave-to {
    transform: translateY(100vh);
}
</style>