<template>
    <div class="home d-flex flex-column bg-white">
        <div class="home-banner px-3  pt-2">
            <div class="d-flex py-3 pt-2 justify-content-between align-items-end">
                <div class="text-dark">
                    <img src="@/assets/home-logo.png" style="height: 50px;" alt="">

                </div>
                <div v-if="currentUser"
                    class="border border-danger d-flex align-items-center justify-content-center rounded-circle p-2"
                    style="aspect-ratio: 1/1;width: 35px;height: 35px;">
                    <router-link :to="{ name: 'notifications' }">
                        <font-awesome-icon class="text-danger" :class="{ 'fa-shake': currentUser.has_notification }"
                            icon="fa-solid fa-bell" size="lg"></font-awesome-icon>
                    </router-link>
                </div>
            </div>


            <div v-if="logined" class="welcome-box">
                <div class="d-flex align-items-center user-info bg-white text-dark p-3 pb-1">
                    <div>
                        <img :src="currentUser.image" class="rounded-circle" width="40" height="40" alt="">
                    </div>
                    <div class="text-dark text-start ms-2">
                        <h1 class="user-name mb-0">{{ currentUser.name }}</h1>
                        <p class="user-phone fw-bold fs-18 text-danger mb-0">{{ currentUser.balance ?
                            $helper.formattedNumber(currentUser.balance)
                            : 0 }} Ks </p>
                    </div>
                    <div class="ms-auto user-info-edit bg-custom rounded-circle">
                        <font-awesome-icon @click="refresh" class="text-danger" :class="{ 'fa-spin': refreshing }" size="sm"
                            icon="fa-solid fa-arrows-rotate"></font-awesome-icon>
                    </div>
                </div>

                <hr class="mx-4 mb-1">

                <div class="user-actions py-2 row mx-2 justify-content-around">
                    <router-link class="text-decoration-none col-4 px-4" :to="{ name: 'top-up' }">
                        <PrimaryButton class="mb-1 action py-3" text="danger">
                            <font-awesome-icon icon="fa-solid fa-circle-dollar-to-slot" size="xl"></font-awesome-icon>
                        </PrimaryButton>
                        <p class="mb-0 fw-400 fs-13 text-danger fw-bold">ငွေဖြည့်</p>
                    </router-link>
                    <router-link class="text-decoration-none col-4 px-4" :to="{ name: 'withdraw' }">
                        <PrimaryButton class="mb-1 action py-3" text="danger">
                            <font-awesome-icon icon="fa-solid fa-coins" size="xl"></font-awesome-icon>
                        </PrimaryButton>
                        <p class="mb-0 fw-400 fs-13 text-danger fw-bold">ငွေထုတ်</p>
                    </router-link>
                    <router-link class="text-decoration-none col-4 px-4" :to="{ name: 'transaction-history' }">
                        <PrimaryButton class="mb-1 action py-3" text="danger">
                            <font-awesome-icon icon="fa-solid fa-rectangle-list" size="xl" />
                        </PrimaryButton>
                        <p class="mb-0 fw-400 fs-13 text-danger fw-bold">မှတ်တမ်း</p>
                    </router-link>
                </div>

                <!-- <div>
                <div class="user-balance-info text-start p-4 d-flex align-items-center">
                    <div>
                        <p class="user-phone mb-0">Available Balance</p>
                        <h1 class="user-balance mb-0">{{ currentUser.balance ? $helper.formattedNumber(currentUser.balance)
                            : 0 }} Unit</h1>
                    </div>
                    <router-link :to="{ name: 'top-up' }" class="ms-auto text-decoration-none">
                        <span class="ms-auto text-custom user-balance-deposit-label">ငွေဖြည့်မည်</span>
                    </router-link>
                </div>

                <div class="user-actions d-flex justify-content-around">
                    <router-link class="text-decoration-none" :to="{ name: 'top-up' }">
                        <SecondaryButton :disabled="false" class="px-3 mb-1 action">
                            <font-awesome-icon icon="fa-solid fa-plus"></font-awesome-icon>
                        </SecondaryButton>
                        <p class="mb-0 fw-400 fs-13">ငွေဖြည့်</p>
                    </router-link>
                    <router-link class="text-decoration-none" :to="{ name: 'withdraw' }">
                        <SecondaryButton class="px-3 mb-1 action">
                            <font-awesome-icon icon="fa-solid fa-circle-up"></font-awesome-icon>
                        </SecondaryButton>
                        <p class="mb-0 fw-400 fs-13">ငွေထုတ်</p>
                    </router-link>
                    <router-link class="text-decoration-none" :to="{ name: 'transaction-history' }">
                        <SecondaryButton class="px-3 mb-1 action">
                            <font-awesome-icon icon="fa-solid fa-clock-rotate-left" />
                        </SecondaryButton>
                        <p class="mb-0 fw-400 fs-13">မှတ်တမ်း</p>
                    </router-link>
                </div>
            </div> -->

            </div>
            <div v-else class="welcome-box p-3">
                <!-- <img src="@/assets/home-logo.svg"> -->
                <!-- <h1 class="welcome-title">Welcome to LuckyMen 2D</h1> -->
                <p class="subtitle fw-bold">အကောင့်ဖွင့်ပါ (သို့) Log in ဝင်ပါ</p>
                <div class="d-flex">
                    <router-link class="w-50 me-1 text-decoration-none" :to="{ name: 'login' }">
                        <SecondaryButton>အကောင့်ဝင်ရန်</SecondaryButton>
                    </router-link>
                    <router-link class="w-50 me-1 text-decoration-none" :to="{ name: 'register' }">
                        <PrimaryButton class="text-dark">အကောင့်အသစ်ဖွင့်ရန်</PrimaryButton>
                    </router-link>
                </div>
            </div>
        </div>


        <div class="p-3 bg-white content-box" style="flex-grow: 1;">
            <div class="ad-box mb-3 d-flex flex-column">
                <div style="position: relative;">
                    <carousel :items-to-show="1" :autoplay="5000" :wrap-around="true" style="overflow: hidden;">
                        <slide v-for="slide in bannerImages" :key="slide">
                            <img :src="slide.image" alt="">
                        </slide>

                        <template #addons>
                            <navigation />
                            <div style="position: absolute;bottom:0;right:0;left:0;margin:auto">
                                <pagination />
                            </div>
                        </template>
                    </carousel>
                </div>
                <div class="mt-auto ad-description-box">
                    <marquee class="ad-description text-danger" behavior="" direction="">
                        <font-awesome-icon class="me-2" icon="fa-solid fa-bullhorn"></font-awesome-icon>
                        {{ homeBannerText }}
                    </marquee>
                </div>

            </div>



            <div class="games-box mb-2">
                <!-- <h1 class="title">ဂိမ်းများ</h1> -->
                <div class="">
                    <router-link :to="{ name: '2d' }" class="text-decoration-none">
                        <div class="game mb-3 text-custom">
                            2D
                        </div>
                    </router-link>
                    <router-link :to="{ name: '3d' }" class="text-decoration-none">
                        <div class="game mb-3 text-custom">
                            3D
                        </div>
                    </router-link>
                </div>
            </div>

            <div class="mb-2 bg-custom align-items-center rounded rounded-4 border border-danger p-2 pt-3">
                <div class="row">

                    <div class="col-6">
                        <p class="fs-16 fw-bold mb-0 text-center">
                            <font-awesome-icon :icon="['fas', 'fa-check-circle']"></font-awesome-icon>
                            12:01 PM
                        </p>
                        <p v-if="live_data" class="fs-36 fw-bold text-center mb-0 text-danger">{{
                            live_data['1200'].trim().substring(0, 2) || '??'
                        }}</p>
                    </div>
                    <!-- <div class="col-6 d-flex align-items-center justify-content-center">
                        <p class="fs-14 fw-bold mb-0 text-center">{{ today }}</p>

                    </div> -->
                    <div class="col-6">
                        <p class="fs-16 fw-bold mb-0 text-center">
                            <font-awesome-icon :icon="['fas', 'fa-check-circle']"></font-awesome-icon>
                            4:30 PM
                        </p>
                        <p v-if="live_data" class="fs-36 fw-bold text-center mb-0 text-danger">{{
                            live_data['430'].trim().substring(0, 2) || '??'
                        }}</p>
                    </div>
                </div>
            </div>

            <div v-if="threed_data" class="border bg-custom rounded rounded-4 border-danger p-3">
                <div class="">
                    <div class="d-flex mb-1">
                        <div class="w-50 text-start fw-500 fs-14">3D</div>
                        <div class="w-50 text-end fw-500 fs-14">ဂဏန်း</div>
                    </div>
                    <div class="d-flex align-items-center">
                        <h1 class="fw-600 fs-14 mb-0">{{ threed_data.date }}</h1>
                        <p class="ms-auto fw-600 text-danger fs-24 mb-0" style="line-height: 1;">{{ threed_data.number }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import 'vue3-carousel/dist/carousel.css'
import userService from '@/services/user.service';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';

export default {
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
        PrimaryButton
    },
    data() {
        return {
            logined: this.$store.state.auth.status.loggedIn,
            refreshing: false,
            live_data: null,
            threed_data: null
        }
    },
    computed: {
        homeBannerText() {
            return this.$store.state.index.homeBannerText
        },
        bannerImages() {
            return this.$store.state.index.homeBannerImages;
        },
        currentUser() {
            return this.$store.state.auth.user;
        },
        today() {
            return new Date().toISOString().slice(0, 10)
        }
    },
    mounted() {
        if (this.logined) {
            this.$store.dispatch('auth/updateUserInfo')
        }

        this.getLiveData()
        this.get3DLatestResult()
    },
    methods: {
        async refresh() {
            if (!this.refreshing) {
                this.refreshing = true
                await this.$store.dispatch('auth/updateUserInfo')
                this.refreshing = false
            }
        },
        getLiveData() {
            userService.get2DLive().then(({ data }) => {
                this.live_data = data[0]
            }).catch(error => {
                console.error('Error:', error);
            });
        },
        get3DLatestResult() {
            userService.get3DLatestResult().then(({ data }) => {
                this.threed_data = data.data
            })
        }
    }
}
</script>
<style>
.content-box {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    margin-top: -30px;

}

.home-banner {
    width: 100%;
    background-color: #FFC107 !important;
    padding-bottom: 50px !important;
}

.banner-title {
    font-weight: 600;
    font-size: 16px;
}

.welcome-box {
    /* margin-top: -30px; */
    background-color: #fff;
    box-shadow: 0px 4px 24px 0px #6D7FA333;
    border-radius: 14px;
    text-align: center;
}

.welcome-box .user-info {
    border-radius: 14px;
    border-top-left-radius: 14px;
}

.ad-box {
    border: 2px solid #ECEEF5;
    border-radius: 14px;
}

.ad-box img {
    border-radius: 14px 14px 0px 0px;
    width: 100%;
    aspect-ratio: 5/2;
    object-fit: cover;
}

.ad-box .ad-description {
    font-size: 13px;
    font-weight: 400;
}

.ad-box .ad-description-box {
    background-color: #FFC107;
    border-color: #FFC107 !important;
    padding-top: 5px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.user-balance-deposit-label {
    font-weight: 400;
    font-size: 12px;
}

.user-name {
    font-weight: 600;
    font-size: 14px;
}

.user-phone {
    font-weight: 400;
    font-size: 12px;
}

.user-balance {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 0;
}

.user-info-edit {
    background: #00000033;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.welcome-box .welcome-title {
    font-weight: 600;
    font-size: 18px;
}

.games-box .title {
    font-weight: 700;
    font-size: 16px;
}

.user-actions .action {
    /* aspect-ratio: 1/1; */
    /* width: 33.33% !important; */
    /* height: 13vw !important; */
}


.user-actions {
    border-radius: 14px;

}

.home {
    overflow-y: auto;
}

.carousel {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.games-box .game {
    background: url('../assets/background.png');
    height: 12vh;
    min-height: 110px;
    background-size: cover;
    border-radius: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 2.9rem;
    text-decoration: none !important;
    font-weight: bold;
}
</style>

