export default {
     getNumbers(number, amount) {
        let data = [];
            if (number.includes('R')) {
                let rPos = number.indexOf('R')
                let num = number
                if (rPos == 2) {
                    let rNum = number[1] + number[0];
                    let num = number[0] + number[1];
                    let nums = [num, rNum];
                    nums.forEach(element => {
                        data.push({
                            number: element,
                            // amount: amount,
                        })
                    });
                } else {
                    let firstNum = num[0] + '' + num[1];
                    let firstNumAmount = parseInt(num.substring(2, rPos));
                    data.push({
                        number: firstNum,
                        amount: firstNumAmount
                     })
                    
                    let secondNum = firstNum[1] + '' + firstNum[0];
                    let secondNumAmount = amount;
                    data.push({
                        number: secondNum,
                        amount: secondNumAmount
                    })
               }
            }
            else if (number.includes("APP")) {
                let numbers = number.split('APP')[0].split('')
                 const regex = new RegExp(`[${numbers}]{2}`);
                for (let i = 0; i <= 99; i++) {
                    let number = i.toString().padStart(2, '0');
                    if (regex.test(number) && number[0] != number[1]) {
                        data.push({
                            number: number,
                            // amount: amount
                        })
                    }
                }
            }
            else if (number.includes("AP")) {
                let numbers = number.split('AP')[0].split('')
                 const regex = new RegExp(`[${numbers}]{2}`);
                for (let i = 0; i <= 99; i++) {
                    let number = i.toString().padStart(2, '0');
                    if (regex.test(number)) {
                        data.push({
                            number: number,
                            // amount: amount
                        })
                    }
                }
            }
            else if(number.includes("NK")) {
                ['01', '10', '12', '21', '23', '32', '34', '43', '45', '54', '56', '65', '67', '76', '78', '87', '89', '98', '90', '09']
.forEach(number => {
                    data.push({
                        number: number,
                        // amount: amount
                    })
                })
            }
            else if(number.includes("W")) {
                let nums = ['05', '16', '27', '38', '49', '50', '61', '72', '83', '94'];
                nums.forEach(element => {
                    data.push({
                        number: element,
                        // amount: amount,
                    })
                });
            }
            else if (number.includes("+")) {
                for (let i = 0; i <= 99; i++) {
                    let num = i.toString().padStart(2, '0');
                    let findNum = number[0];
                    if (num.toString().includes(findNum)) {
                        data.push({
                            number: num,
                            // amount: amount
                        })
                    }
                }
            }
            else if(number.includes("SS")) {
                for (let i = 0; i <= 99; i++) {
                    let number = i.toString().padStart(2, '0');
                    if (number[0] % 2 == 0 && number[1] % 2 == 0) {
                        data.push({
                            number: number,
                            // amount: amount
                        })
                    }
                }
            }
            else if(number.includes("MM")) {
                for (let i = 0; i <= 99; i++) {
                    let number = i.toString().padStart(2, '0');
                    if (number[0] % 2 != 0 && number[1] % 2 != 0) {
                        data.push({
                            number: number,
                            // amount: amount
                        })
                    }
                }
            }
            else if(number.includes("SM")) {
                for (let i = 0; i <= 99; i++) {
                    let number = i.toString().padStart(2, '0');
                    if (number[0] % 2 == 0 && number[1] % 2 != 0) {
                        data.push({
                            number: number,
                            // amount: amount
                        })
                    }
                }
            }
            else if(number.includes("SP")) {
                for (let i = 0; i <= 99; i++) {
                    let number = i.toString().padStart(2, '0');
                    if (number[0] == number[1] && number[0] % 2 == 0 && number[1] % 2 == 0) {
                        data.push({
                            number: number,
                            // amount: amount
                        })
                    }
                }
            }
            else if(number.includes("MP")) {
                for (let i = 0; i <= 99; i++) {
                    let number = i.toString().padStart(2, '0');
                    if (number[0] == number[1] && number[0] % 2 != 0 && number[1] % 2 != 0) {
                        data.push({
                            number: number,
                            // amount: amount
                        })
                    }
                }
            }
                
            else if(number.includes("MS")) {
                for (let i = 0; i <= 99; i++) {
                    let number = i.toString().padStart(2, '0');
                    if (number[0] % 2 != 0 && number[1] % 2 == 0) {
                        data.push({
                            number: number,
                            // amount: amount
                        })
                    }
                }
            }
            else if (number.includes("Even")) {
                for (let i = 0; i <= 99; i++) {
                    if (i % 2 == 0) {
                        let num = i.toString().padStart(2, '0');
                        data.push({
                            number: num,
                            // amount: amount
                        })
                    }
                }
            }
            else if (number.includes("Odd")) {
                for (let i = 0; i <= 99; i++) {
                    if (i % 2 != 0) {
                        let num = i.toString().padStart(2, '0');
                        data.push({
                            number: num,
                            // amount: amount
                        })
                    }
                }
            }
            else if (number.includes("Big")) {
                for (let i = 0; i <= 99; i++) {
                    if (i >= 50) {
                        let num = i.toString().padStart(2, '0');
                        data.push({
                            number: num,
                            // amount: amount
                        })
                    }
                }
            }
            else if (number.includes("Small")) {
                for (let i = 0; i <= 99; i++) {
                    if (i < 50) {
                        let num = i.toString().padStart(2, '0');
                        data.push({
                            number: num,
                            // amount: amount
                        })
                    }
                }
            }
            else if(number.includes("M")) {
                for (let i = 0; i <= 99; i++) {
                    let num = i.toString().padStart(2, '0');

                    let numPos = num.indexOf(number[0]);

                    if (numPos != -1) {
                        if (numPos == 0 && num[1] % 2 != 0 ) {
                            data.push({
                                number: num,
                                // amount: amount
                            })
                        }

                        if (numPos == 1 && num[0] % 2 != 0 ) {
                            data.push({
                                number: num,
                                // amount: amount
                            })
                        }
                    }

                    
                }
            }
            else if(number.includes("S")) {
                for (let i = 0; i <= 99; i++) {
                    let num = i.toString().padStart(2, '0');

                    let numPos = num.indexOf(number[0]);

                    if (numPos != -1) {
                        if (numPos == 0 && num[1] % 2 == 0 ) {
                            data.push({
                                number: num,
                                // amount: amount
                            })
                        }

                        if (numPos == 1 && num[0] % 2 == 0 ) {
                            data.push({
                                number: num,
                                // amount: amount
                            })
                        }
                    }

                    
                }
            }
            else if(number.includes("K")) {
                ['18','81','70','07','42','24','69','96','53','35'].forEach(number => {
                    data.push({
                        number: number,
                        // amount: amount
                    })
                })
            }
                
            else if(number.includes("P")) {
                let nums = ['00', '11', '22', '33', '44', '55', '66', '77', '88', '99'];
                nums.forEach(element => {
                    data.push({
                        number: element,
                        // amount: amount,
                    })
                });
            }
            else if(number.includes("B")) {
                for (let i = 0; i <= 99; i++) {
                    let num = i.toString().padStart(2, '0');
                    let sum = parseInt(num[0]) + parseInt(num[1])
                    if (sum.toString()[sum.toString().length - 1]  == number[0]) {
                        data.push({
                            number: num,
                            // amount: amount
                        })
                    }
                }
            }
           
            else if (number.includes("*")) {
                if (number.toString().indexOf('*') == 0) {
                    let num = number[1];
                    for (let i = 0; i <= 99; i++) {
                        let number = i.toString().padStart(2, '0');
                        if (number[1] == num) {
                            data.push({
                                number: number,
                                // amount: amount
                            })
                        }
                    }
                }

                if (number.toString().indexOf('*') == 1) {
                    let num = number[0];
                    for (let i = 0; i <= 99; i++) {
                        let number = i.toString().padStart(2, '0');
                        if (number[0] == num) {
                            data.push({
                                number: number,
                                // amount: amount
                            })
                        }
                    }
                }
                
            }
            else if (number.includes("-")) {
                let start = number.split('-')[0]
                let end = number.split('-')[1]

                for (let i = 0; i <= 99; i++) {
                    if (i >= parseInt(start) && i <= parseInt(end)) {
                        let num = i.toString().padStart(2, '0');
                        data.push({
                            number: num,
                            // amount: amount
                        })
                    }
                }
            }

        return data;
    },
}