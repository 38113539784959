<template>
    <div>
        <div class="confirmation-box-backdrop" v-if="show" @click="$emit('close')">
        </div>
        <Transition name="slide" appear>
            <div class="confirmation-box-wrapper" v-if="show">

                <div class="confirmation-box rounded-3" @click.stop.prevent>
                    <div class="p-4">
                        <font-awesome-icon v-if="icon" size="2xl" class="text-custom mb-4" :icon="icon" />
                        <h1 class="fw-600 fs-14">{{ title }}</h1>
                        <p class="fw-400 fs-13 mb-0">{{ subtitle }}</p>
                    </div>
                    <div class="horizontal-line mt-auto"></div>
                    <div class="d-flex fw-600 fs-14 p-3">
                        <div class="col-6" @click="$emit('cancel')">
                            {{ cancelButtonText }}
                        </div>
                        <div class="col-6 text-danger" @click="$emit('ok')">
                            {{ okButtonText }}
                        </div>
                    </div>
                </div>

            </div>
        </Transition>
    </div>
</template>
<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        subtitle: {
            type: String,
            default: null
        },
        okButtonText: {
            type: String,
            default: "OK"
        },
        cancelButtonText: {
            type: String,
            default: "Cancel"
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
    },
};
</script>
<style>
.confirmation-box-backdrop {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.confirmation-box-wrapper {
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

}

.confirmation-box {
    text-align: center;
    z-index: 1000;
    width: 80%;
    max-width: 350px;
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
}

.confirmation-box-header,
.confirmation-box-footer {
    padding: 15px;
    display: flex;
}

.confirmation-box-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #4AAE9B;
    justify-content: space-between;
}

.confirmation-box-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
}

.confirmation-box-body {
    position: relative;
    padding: 20px 10px;
}

.btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
}

.btn-green {
    color: white;
    background: #4AAE9B;
    border: 1px solid #4AAE9B;
    border-radius: 2px;
}

/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-enter-active {
    transition: all 0.4s ease-out;
}

.slide-leave-active {
    transition: all 0.4s ease-in;
}

.slide-enter-from,
.slide-leave-to {
    transform: translateY(50vh);
}
</style>