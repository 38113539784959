<template>
    <div class="toast-box rounded-3 p-3" v-if="message">
        <font-awesome-icon icon="fa-solid fa-circle-exclamation" class="text-danger" size="2xl" />
        <p class="mb-0 fw-400 fs-14 text-center text-dark mt-2 d-flex align-items-center" style="flex-grow: 1;">{{ message }}</p>
    </div>
</template>
<script>
export default {
    props: {
        message: {
            type: String
        }
    }
}

</script>
<style scoped>
.toast-box {
    background-color: #FFC107;
    height: 15vh;
    min-height: 130px;
    max-width: 200px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
    color: white;
}
</style>