<template>
    <div>
        <div class="modal-box-backdrop" v-if="show" @click="$emit('close')">
        </div>
        <Transition name="slide" appear>
            <div class="modal-box p-4" v-if="show" @click.stop.prevent>
                <div style="position: relative;">
                    <h1 class="fw-600 mb-4 fs-14 text-center">
                        <slot name="header">
                        </slot>
                    </h1>
                    <font-awesome-icon style="position: absolute;right: 0;top: 0;" :icon="['fas', 'fa-times']" size="lg"
                        @click="$emit('close')"></font-awesome-icon>
                </div>

                <div class="body">
                    <slot name="body" class="w-100">
                    </slot>
                </div>

                <slot name="footer">
                </slot>
            </div>
        </Transition>
    </div>
</template>
<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
    },
};
</script>
<style>
.modal-box-backdrop {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: end;
    z-index: 999;
}

.modal-box {
    border-radius: 1.1rem 1.1rem 0 0;
    z-index: 1000;
    width: 100%;
    max-width: 450px;
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.body {
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.modal-box-header,
.modal-box-footer {
    padding: 15px;
    display: flex;
}

.modal-box-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #4AAE9B;
    justify-content: space-between;
}

.modal-box-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
}

.modal-box-body {
    position: relative;
    padding: 20px 10px;
}

.btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
}

.btn-green {
    color: white;
    background: #4AAE9B;
    border: 1px solid #4AAE9B;
    border-radius: 2px;
}

/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-enter-active {
    transition: all 0.4s ease-out;
}

.slide-leave-active {
    transition: all 0.4s ease-in;
}

.slide-enter-from,
.slide-leave-to {
    transform: translateY(50vh);
}
</style>