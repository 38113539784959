<template>
    <div class="p-4" style="height: calc(100vh - 30px);overflow-y: auto;">
        <div v-for="(notifications, index) in notifiables" :key="index" class="mb-4">
            <p class="fw-400 mb-0 fs-14">{{ index }}</p>
            <ul class="list-group list-group-flush">
                <li class="list-group-item" v-for=" (notification, index) in notifications" :key="index">
                    <div class="py-2 text-decoration-none d-flex align-items-center text-dark">
                        <img style="width: 40px;" v-if="notification.type == 'deposit'" src="@/assets/cash-in-rounded.svg"
                            class="icon">
                        <img style="width: 40px;" v-else-if="notification.type == 'withdraw'"
                            src="@/assets/cash-out-rounded.svg" class="icon">
                        <img style="width: 40px;" v-else-if="notification.type == 'win'" src="@/assets/win.svg"
                            class="icon">
                        <img style="width: 40px;" v-else-if="notification.type == 'warning'" src="@/assets/warning.svg"
                            class="icon">
                        <img style="width: 40px;" v-else-if="notification.type == 'admin'" src="@/assets/warning.svg"
                            class="icon">
                        <div class="ms-2">
                            <span class="fw-600 fs-13">{{ notification.content }}</span>
                        </div>

                    </div>
                </li>
            </ul>
            <div class="transaction-line"></div>
        </div>
    </div>
</template>
<script>
import userService from '@/services/user.service'
export default {
    data() {
        return {
            notifiables: []
        }
    }
    ,
    mounted() {
        this.$store.dispatch('loading')
        userService.getNotifications().then(({ data }) => {
            this.$store.dispatch('loadingComplete')
            this.notifiables = data.data
            console.log(this.notifiables)
        })
    },
}
</script>
<style>
.transaction-line {
    border: 1px solid #EBF0FD;
}


.icon {
    width: 6vw;
    aspect-ratio: 1/1;
}
</style>