<template>
    <div class="p-4">
        <template v-if="step == 1">
            <form @submit.prevent="goToStep2()" class="d-flex flex-column h-100">

                <label for="payment_method" class="mb-2">Select Payment Method</label>

                <div class="options d-flex p-0 mb-4">
                    <div @click="selectPayment(key)" class="col-6" :class="{
                        'pe-1': (key + 1 % 2 != 0),
                        'ps-1': (key + 1 % 2 == 0),
                    }" v-for="(value, key) in payment_options" :key="key">
                        <div :class="{
                            'border-custom': withdraw_payment == key,
                            'border border-2': withdraw_payment != key,

                            'selected-payment': withdraw_payment == key
                        }" class="d-flex align-items-center rounded px-2 py-2">
                            <img :src="require('@/assets/payments/' + value.icon)" alt="">
                            <label class="form-check-label mx-2" for="inlineRadio2">{{ value.name }}</label>

                        </div>
                    </div>

                </div>
                <div class="">
                    <form-group v-model="withdraw_account_name" :errors="errors.withdraw_account_name" :required="true"
                        label="ငွေလက်ခံမည့်အကောင့်နာမည်" placeholder="ငွေလက်ခံမည့်အကောင့်နာမည်ရိုက်ထည့်ပါ"></form-group>
                    <form-group v-model="withdraw_account_number" type="number" :errors="errors.withdraw_account_number"
                        :required="true" label="ငွေလက်ခံမည့်အကောင့်နံပါတ်"
                        placeholder="ငွေလက်ခံမည့်အကောင့်နံပါတ်ရိုက်ထည့်ပါ"></form-group>
                    <form-group v-model="confirm_withdraw_account_number" type="number" :required="true"
                        label="ငွေလက်ခံမည့်အကောင့်နံပါတ်"
                        placeholder="ငွေလက်ခံမည့်အကောင့်နံပါတ်ပြန်ရိုက်ထည့်ပါ"></form-group>

                </div>
                <hr>

                <div class="mt-2">
                    <form-group v-model="amount" :errors="errors.amount" :required="true" :min="0"
                        :max="currentUser.balance" label="Amount" placeholder="Amount ရိုက်ထည့်ပါ"></form-group>
                </div>


                <!-- <div class="mt-4">
                    <h2 class="fw-600 fs-14 mb-3">Select amount</h2>
                    <div class="row">
                        <div @click="selectAmount(amount)" v-for="(amount, key) in  amount_options" :key="key"
                            class="col-4 mb-2 text-center">
                            <div :class="{
                                'bg-custom text-white': selectedAmount == amount,
                                'text-custom bg-amount': selectedAmount != amount
                            }" class="text-center py-2 rounded">
                                {{ $helper.formattedNumber(amount) }}
                            </div>
                        </div>
                    </div>
                </div> -->

                <PrimaryButton type="submit" class="login-btn mt-auto">ဆက်သွားမည်</PrimaryButton>
                <!-- <template v-else-if="step == 2">
                <div class="d-flex align-items-center">
                    <img style="width: 15vw;" :src="require('@/assets/payments/' + payment_options[withdraw_payment].icon)"
                        alt="">

                    <div class="ms-2">
                        <span class="fw-400 fs-12">ငွေလက်ခံမည့် နံပါတ်</span>
                        <h1 class="fw-600 fs-14 mb-0">{{ withdraw_account_name }}</h1>
                        <span class="fw-400 fs-12">{{ withdraw_account_number }}</span>
                    </div>
                </div>







                <div class="mt-3 d-flex align-items-center">
                    <font-awesome-icon icon="fa-brands fa-youtube" class="me-2 text-custom" size="lg" />
                    <span class="fw-400 fs-13 text-decoration-underline">ငွေထုတ်နည်း ကြည့်ရန် နှိပ်ပါ။</span>
                </div>

                <div class="mt-auto d-flex">
                    <div class="w-50 me-1">
                        <OrdinaryButton @click="step = 1" type="submit" class="login-btn mt-auto fw-700 fs-14">နောက်သို့
                        </OrdinaryButton>

                    </div>
                    <div class="w-50 ms-1">
                        <PrimaryButton @click="step = 3" type="submit" class="login-btn mt-auto fw-700 fs-14">
                            တင်ပြမည်
                        </PrimaryButton>
                    </div>
                </div>
            </template> -->
            </form>
        </template>

        <template v-else>
            <form @submit.prevent="applyWithdraw()" class="d-flex flex-column justify-content-between h-100">

                <p class="fw-400 fs-14">ငွေထုတ်ရန် စကားဝှက် ရိုက်ထည့်ပါ</p>

                <div>
                    <FormGroup type="password" v-model="pin" :errors="errors.pin" placeholder="စကားဝှက် ရိုက်ထည့်ပါ">
                    </FormGroup>
                </div>

                <div class="text-end text-custom">
                    <span class="fw-400 fs-14">Forgot your PIN?</span>
                </div>

                <div class="row mt-auto">
                    <div class="col-6">
                        <SecondaryButton @click="step = 1" class="login-btn">ရှေ့သို့ </SecondaryButton>
                    </div>
                    <div class="col-6">
                        <PrimaryButton type="submit" class="login-btn">တင်ပြမည် </PrimaryButton>
                    </div>
                </div>
            </form>
        </template>

        <hr>
        <div class="border-custom mt-2 rounded mb-4">
            <div class="py-2 bg-custom">
                <p class="fw-bold fs-14 mb-0 text-center">ငွေဖြည့်နည်းမသိပါက ဆက်သွယ်ရန်</p>
            </div>

            <div class="p-2 pb-0">
                <div class="row ">
                    <div class="col-12 mb-2" v-for="(types, index) in $store.state.index.services" :key="index">
                        <div class="text-start fs-13" v-if="index == 1">
                            Viber
                        </div>
                        <div class="text-start fs-13" v-if="index == 2">
                            Telegram
                        </div>
                        <div class="text-start fs-13" v-if="index == 3">
                            Phone
                        </div>
                        <div class="row">
                            <div v-for="(phone, i) in types" :key="i" class="col-6 d-flex mb-1">
                                <a v-if="index == 1" :href="'viber://contact?number=' + phone.phone"
                                    class="d-flex align-items-center text-decoration-none">
                                    <font-awesome-icon class="text-custom me-2" icon="fas fa-phone"></font-awesome-icon>
                                    <div class="fw-bold fs-16 text-custom ">
                                        {{ phone.phone }}
                                    </div>
                                </a>
                                <a v-if="index == 2" :href="'https://t.me/number=' + phone.phone"
                                    class="d-flex align-items-center text-decoration-none">
                                    <font-awesome-icon class="text-custom me-2" icon="fas fa-phone"></font-awesome-icon>
                                    <div class="fw-bold fs-16 text-custom ">
                                        {{ phone.phone }}
                                    </div>
                                </a>
                                <a v-if="index == 3" :href="'tel:' + phone.phone"
                                    class="d-flex align-items-center text-decoration-none">
                                    <font-awesome-icon class="text-custom me-2" icon="fas fa-phone"></font-awesome-icon>
                                    <div class="fw-bold fs-16 text-custom ">
                                        {{ phone.phone }}
                                    </div>
                                </a>


                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>

        <modal :show="showWarningModal">
            <template v-slot:header>ငွေထုတ်ရင်သတိပြုရမည့်အချက်များ</template>
            <template v-slot:body>
                <ul class="">
                    <li class="fw-400 fs-14">
                        Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat
                        duis
                        enim
                        velit mollit. Exercitation veniam consequat sunt nostrud amet.
                    </li>
                    <li class="fw-400 fs-14">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit
                        officia consequat
                        duis
                        enim
                        velit mollit.</li>
                </ul>

                <PrimaryButton @click="showWarningModal = false">OK</PrimaryButton>
            </template>
        </modal>
    </div>
</template>
<script>
import Modal from '@/components/ModalComponent.vue';
import SecondaryButton from '@/components/buttons/SecondaryButton.vue';
import userService from '@/services/user.service';
export default {
    components: {
        Modal,
        SecondaryButton
    },
    data() {
        return {
            step: 1,
            showWarningModal: false,
            selectedAmount: null,

            withdraw_account_name: null,
            withdraw_account_number: null,
            withdraw_payment: null,
            confirm_withdraw_account_number: null,
            pin: null,
            amount: null,
            payment_options: [
                {
                    name: "KBZ Pay",
                    icon: "kpay.svg",
                    id: 1,
                },
                {
                    name: "Wave Pay",
                    icon: "wave.svg",
                    id: 2,
                }
            ],
            errors: {},
            amount_options: [
                5000, 10000, 50000, 100000, 2000000
            ]
        };
    },
    mounted() {
        this.withdraw_account_name = this.currentUser.withdraw_account_name
        this.withdraw_account_number = this.currentUser.withdraw_account_number
        this.confirm_withdraw_account_number = this.currentUser.confirm_withdraw_account_number
        this.withdraw_payment = this.currentUser.withdraw_payment ? this.payment_options.findIndex(payment => payment.id == this.currentUser.withdraw_payment.id) : 0
    },
    methods: {
        applyWithdraw() {
            let data = {
                withdraw_account_name: this.withdraw_account_name,
                withdraw_account_number: this.withdraw_account_number,
                withdraw_account_number_confirmation: this.confirm_withdraw_account_number,
                withdraw_payment: this.payment_options[this.withdraw_payment].id,
                amount: this.amount,
                pin: this.pin
            };

            this.$store.dispatch('loading')
            userService.applyWithdraw(data).then((response) => {
                this.$store.dispatch('loadingComplete')
                if (response.status) {
                    this.$router.push({
                        name: "home"
                    });
                }
            }).catch(({ response }) => {
                this.$store.dispatch('loadingComplete')
                if (response.status == 422) {
                    this.errors = response.data.errors
                    this.pin = null
                    if (this.errors.amount) {
                        this.step = 1
                    }
                }
            });
        },
        selectPayment(index) {
            this.withdraw_payment = index;
        },
        selectAmount(amount) {
            this.selectedAmount = amount;
        },
        goToStep2() {
            if (this.withdraw_account_number != this.confirm_withdraw_account_number) {
                this.$store.dispatch('showError', 'Not same number')
                return;
            }
            this.step = 2
        }
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user
        }

    }
}
</script>
<style>
.selected-payment {
    background-color: #FFC1071A;
}

.bg-amount {
    background: #EBF0FD;
}

.amount-input {
    background: #EBF0FD;
    border: 0;
    border-radius: .7rem;
    width: 100%;
    padding: .8rem 1.2rem;
    font-size: .85rem;
}
</style>
