<template>
    <div class="two-d">
        <div class="content">
            <div class="">
                <div class="p-3 banner d-flex flex-column align-items-center justify-content-center">
                    <div class="winning-number border border-danger border-2">
                        <span class="fw-600 fs-36 text-danger " :class="{ 'blink': activeAm || activePm }">{{ live_data.live
                        }}</span>
                    </div>
                    <div class="d-flex align-items-center mt-3">
                        <font-awesome-icon icon="fa-solid fa-circle-check" size="sm" class="text-danger me-2" />
                        <span class="fw-400 fs-13 text-dark">Updated : {{ live_data.updatetime }}</span>
                    </div>
                </div>
                <!-- <h1 class="fw-600 fs-18 mb-0">2D</h1> -->

                <div class="actions d-flex justify-content-around border p-3 border-top border-bottom">
                    <router-link class="text-decoration-none col-4 px-3 text-center"
                        :to="{ name: '2d-betting-list', params: { type: '2d' } }">
                        <PrimaryButton class="mb-1 action py-3 mx-auto" text="danger" style="width: 75% !important;">
                            <font-awesome-icon icon="fa-solid fa-rectangle-list" size="lg"></font-awesome-icon>
                        </PrimaryButton>
                        <p class="mb-0 fw-400 fs-13 text-danger fw-bold">မှတ်တမ်း</p>
                    </router-link>
                    <router-link class="text-decoration-none col-4 px-3 text-center" :to="{ name: '2d.winners' }">
                        <PrimaryButton class="mb-1 action py-3 mx-auto" text="danger" style="width: 75% !important;">
                            <font-awesome-icon icon="fa-solid fa-trophy" size="lg"></font-awesome-icon>
                        </PrimaryButton>
                        <p class="mb-0 fw-400 fs-13 text-danger fw-bold">အနိုင်ရသူများ</p>
                    </router-link>
                    <router-link class="text-decoration-none col-4 px-3 text-center" :to="{ name: '2d-closing-days' }">
                        <PrimaryButton class="mb-1 action py-3 mx-auto" text="danger" style="width: 75% !important;">
                            <font-awesome-icon icon="fa-solid fa-calendar-xmark" size="lg" />
                        </PrimaryButton>
                        <p class="mb-0 fw-400 fs-13 text-danger fw-bold">ပိတ်ရက်များ</p>
                    </router-link>

                </div>
            </div>

            <div class="p-3" v-for="(lottery, index) in lottries" :key="index">
                <h1 class="fw-600 fs-14">{{ index }}</h1>
                <ul class="list-group list-group-flush">
                    <li v-for="(section, index ) in lottery" :key="index"
                        class="list-group-item px-0 w-100 d-flex align-items-center">
                        <div class="col-2">

                            <div class="rounded-circle border-danger border bg-custom2 d-flex align-items-center justify-content-center"
                                style="width: 45px;height: 45px;">
                                <h1 class="fw-600 fs-18 mb-0 text-danger">{{ section.ended ? section.number : '??'
                                }}</h1>

                            </div>
                        </div>

                        <div class="col-7">
                            <div>
                                <span class="fw-400 fs-16 fw-bold">{{ section.time }}</span>
                            </div>
                            <div v-if="section.has_set_value">
                                <span class="fw-400 fs-12 me-3">Set <span class="text-custom">{{ section.ended ?
                                    $helper.formattedNumber(section.set) : '...................'
                                }}</span></span>
                                <span class="fw-400 fs-12">Value <span class="text-custom">{{
                                    section.ended ? $helper.formattedNumber(section.value) : '...................'
                                }}</span></span>
                            </div>
                        </div>
                        <div class="col-3">
                            <PrimaryButton v-if="section.ended" class="text-dark" disabled>ထိုးမည်</PrimaryButton>
                            <router-link v-else :to="{ name: '2d-section', params: { section: section.id } }">
                                <PrimaryButton class="mx-auto w-20 text-dark">ထိုးမည်</PrimaryButton>
                            </router-link>
                        </div>

                    </li>
                </ul>
            </div>



            <!-- <div class="p-3">
                <h1 class="fw-600 fs-14">New York 2D</h1>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item d-flex align-items-center">
                        <div>
                            <div>
                                <font-awesome-icon icon="fa-solid fa-sun" class="text-custom me-2" />
                                <span class="fw-400 fs-16">10:30 AM</span>
                            </div>
                            <div>
                                <span class="fw-400 fs-12 me-3">Set <span class="text-custom">{{
                                    $helper.formattedNumber(1599.82)
                                }}</span></span>
                                <span class="fw-400 fs-12">Value <span class="text-custom">{{
                                    $helper.formattedNumber(32122.25)
                                }}</span></span>
                            </div>
                        </div>
                        <h1 class="ms-auto fw-600 fs-24">06</h1>
                    </li>
                    <li class="list-group-item d-flex align-items-center">
                        <div>
                            <div>
                                <font-awesome-icon icon="fa-solid fa-moon" class="text-custom me-2" />
                                <span class="fw-400 fs-16">12:01 PM</span>
                            </div>
                            <div>
                                <span class="fw-400 fs-12 me-3">Set <span class="text-custom">{{
                                    $helper.formattedNumber(1599.82)
                                }}</span></span>
                                <span class="fw-400 fs-12">Value <span class="text-custom">{{
                                    $helper.formattedNumber(32122.25)
                                }}</span></span>
                            </div>
                        </div>
                        <h1 class="ms-auto fw-600 fs-24">22</h1>
                    </li>
                    <li class="list-group-item d-flex align-items-center">
                        <div>
                            <div>
                                <font-awesome-icon icon="fa-solid fa-sun" class="text-custom me-2" />
                                <span class="fw-400 fs-16">2:30 PM</span>
                            </div>
                            <div>
                                <span class="fw-400 fs-12 me-3">Set <span class="text-custom">{{
                                    $helper.formattedNumber(1599.82)
                                }}</span></span>
                                <span class="fw-400 fs-12">Value <span class="text-custom">{{
                                    $helper.formattedNumber(32122.25)
                                }}</span></span>
                            </div>
                        </div>
                        <h1 class="ms-auto fw-600 fs-24">16</h1>
                    </li>
                    <li class="list-group-item d-flex align-items-center">
                        <div>
                            <div>
                                <font-awesome-icon icon="fa-solid fa-moon" class="text-custom me-2" />
                                <span class="fw-400 fs-16">6:00 PM</span>
                            </div>
                            <div>
                                <span class="fw-400 fs-12 me-3">Set <span class="text-custom">{{
                                    $helper.formattedNumber(1599.82)
                                }}</span></span>
                                <span class="fw-400 fs-12">Value <span class="text-custom">{{
                                    $helper.formattedNumber(32122.25)
                                }}</span></span>
                            </div>
                        </div>
                        <h1 class="ms-auto fw-600 fs-24">27</h1>
                    </li>
                </ul>
            </div>

            <div class="p-3">
                <h1 class="fw-600 fs-14">Internet</h1>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item d-flex align-items-center">
                        <div>
                            <font-awesome-icon icon="fa-solid fa-sun" class="text-custom me-2" />
                            <span class="fw-400 fs-16">10:30 AM</span>
                        </div>
                        <h1 class="ms-auto fw-600 fs-24">06</h1>
                    </li>
                    <li class="list-group-item d-flex align-items-center">
                        <div>
                            <font-awesome-icon icon="fa-solid fa-moon" class="text-custom me-2" />
                            <span class="fw-400 fs-16">5:00 PM</span>
                        </div>
                        <h1 class="ms-auto fw-600 fs-24">38</h1>
                    </li>
                    <li class="list-group-item d-flex align-items-center">
                        <div>
                            <font-awesome-icon icon="fa-solid fa-moon" class="text-custom me-2" />
                            <span class="fw-400 fs-16">2:30 AM</span>
                        </div>
                        <h1 class="ms-auto fw-600 fs-24">75</h1>
                    </li>
                </ul>
            </div> -->

        </div>
        <div class="action-box-wrapper">
            <div class="action-box">
                <div class="d-flex align-items-center">
                    <font-awesome-icon icon="fa-solid fa-money-bill" class="text-custom" />
                    <span class="fw-400 fs-14 mx-2">လက်ကျန်ငွေ</span>
                    <span class="fw-600 fs-14">{{ $helper.formattedNumber(currentUser.balance) }} Ks</span>
                </div>
                <PrimaryButton size="sm" class="fw-700 fs-14 text-dark" @click="showBetModal = true">ထိုးမည်</PrimaryButton>
            </div>
        </div>

        <ModalBox :show="showBetModal" @close="showBetModal = false">
            <template v-slot:header>ထိုးမည့် Section ရွေးပါ</template>
            <template v-slot:body>
                <div class="pb-3" :class="{ 'py-3': x != 0, 'border-bottom': (x + 1) != lottries.length }"
                    v-for="(lottery, x) in lottries" :key="x">
                    <h1 class="fw-600 fs-14">{{ x }}</h1>
                    <div class="d-flex flex-wrap">
                        <div v-for="(value, y) in lottery" :key="y" class="col-12 mb-2">

                            <div @click="$store.dispatch('showError', 'ထီပိတ်သွားပါပီ')" v-if="value.ended"
                                class="text-center section rounded py-2 fw-600 fs-14 ended">
                                {{ (value.time) + ' (' + value.multiply + 'ဆ)' }}
                            </div>

                            <div v-else class="text-center section  rounded py-2 fw-600 fs-14"
                                :class="{ 'active': selectedSection == value.id }" @click="chooseSection(value.id)">
                                {{ (value.time) + ' (' + value.multiply + 'ဆ)' }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex mt-2">
                    <div class="col-12">
                        <PrimaryButton class="fw-700 fs-14" @click="next()" :disabled="selectedSection == null">
                            ဆက်သွားမည်</PrimaryButton>
                    </div>
                </div>
            </template>
        </ModalBox>
    </div>
</template>
<script>
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import ModalBox from '@/components/ModalComponent.vue';
import userService from '@/services/user.service';
export default {
    components: {
        ModalBox,
        PrimaryButton
    },
    data() {
        return {
            logined: this.$store.state.auth.status.loggedIn,
            currentUser: this.$store.state.auth.user,
            showBetModal: false,
            selectedSection: null,
            lottries: [],
            liveDataInterval: null,
            live_data: {
                live: '??',
            },
            currentHour: new Date().getHours(),
            currentMinute: new Date().getMinutes()
        }
    },
    mounted() {

        userService.get2DScheduleList().then(({ data }) => {
            if (data.success) {
                this.lottries = data.data
            }
        })

        this.getLiveData()
        if (this.activeAm || this.activePm) {
            this.startLiveInterval()
        }
    },
    unmounted() {
        clearInterval(this.liveDataInterval)
    },
    computed: {
        activeAm() {
            return (this.currentHour === 9 && this.currentMinute >= 30) ||
                (this.currentHour > 9 && this.currentHour < 12) ||
                (this.currentHour === 12 && this.currentMinute <= 1);
        },
        activePm() {
            return (this.currentHour === 14 && this.currentMinute >= 0) ||
                (this.currentHour > 14 && this.currentHour < 16) ||
                (this.currentHour === 16 && this.currentMinute <= 30);
        }
    },
    methods: {
        chooseSection(section) {
            this.selectedSection = section
        },
        next() {
            this.$router.push({
                name: '2d-section',
                params: {
                    section: this.selectedSection
                }
            })
        },
        startLiveInterval() {
            let that = this
            this.liveDataInterval = setInterval(function () {
                that.getLiveData()
            }, 1000)
        },
        async getLiveData() {
            await userService.get2DLive().then(({ data }) => {
                this.live_data = data[0]
            }).catch(error => {
                console.error('Error:', error);
            });
        }
    }
}
</script>
<style>
.two-d {
    padding-bottom: 12vh;
}

.banner .winning-number {
    border-radius: 50%;
    background-color: #fff;
    color: #FFC107;
    width: 80px !important;
    height: 80px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.two-d .content {
    border-radius: 1.2rem 1.2rem 0 0;
    background-color: #fff;
}



.ending-time {
    height: 6vh;
    width: 75vw;
    right: 0;
    top: 0;
}

.two-d .section {
    background-color: #FFF0A4 !important;
}

.two-d .section.active {
    color: #000;
    background-color: #FFC107 !important;
}

.ended {
    color: #9e9e9e !important;
}

.action-box-wrapper {
    position: fixed;
    bottom: 0;
    width: 100vw;
    max-width: 450px;
    padding: .5rem;
    box-sizing: border-box;

}

.action-box {
    border-radius: 1rem;

    background-color: #FFF0A4 !important;
    padding: 1rem;
    height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@keyframes blink {

    0%,
    50%,
    100% {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0;
    }
}

.winning-number .blink {
    animation: blink 3.5s infinite;
}
</style>