<template>
    <div class="p-4">
        <form @submit="applyTopUp()" class="d-flex flex-column h-100">
            <template v-if="step == 1">
                <div class="options row p-0">
                    <div @click="selectPayment(key)" class="col-6" :class="{
                        'ps-0': (key + 1 % 2 != 0),
                        'pe-0': (key + 1 % 2 == 0),
                    }" v-for="(value, key) in payment_options" :key="key">
                        <div :class="{
                            'border-custom': key == selectedPayment,
                            'border border-2': key != selectedPayment,

                            'selected-payment': key == selectedPayment
                        }" class="d-flex align-items-center rounded px-2 py-2">
                            <img :src="require('@/assets/payments/' + value.icon)" alt="">
                            <label class="form-check-label mx-2" for="inlineRadio2">{{ value.name }}</label>
                            <font-awesome-icon v-if="selectedPayment == key" icon="fa-regular fa-circle-dot"
                                class="text-custom ms-auto" size="lg" />
                            <font-awesome-icon v-else icon="fa-regular fa-circle" class="text-custom ms-auto" size="lg" />
                        </div>
                    </div>

                </div>
                <div class="mt-4">
                    <h1 class="fw-600 fs-18 mb-4">{{ payment_options[selectedPayment].name
                    }}
                        <span class="fw-400 fs-14">နှင််ငွေဖြည့်မည်</span>
                    </h1>
                    <h2 class="fw-600 fs-14 mb-3">Select amount</h2>
                    <div class="row">
                        <div @click="selectAmount(amount)" v-for="(amount, key) in  amount_options" :key="key"
                            class="col-4 mb-2 text-center">
                            <div :class="{
                                'bg-custom text-white': selectedAmount == amount,
                                'text-custom bg-amount': selectedAmount != amount
                            }" class="text-center py-2 rounded">
                                {{ $helper.formattedNumber(amount) }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex align-items-center my-2">
                    <span style="flex-grow:1;height: 1px;background-color: #FFF0A4;"></span>
                    <span class="fw-400 fs-14 mx-2">or</span>
                    <span style="flex-grow:1;height: 1px;background-color: #FFF0A4;"></span>
                </div>

                <form-group :errors="errors.amount" label="Amount" :required="true" placeholder="Enter amount"
                    v-model="selectedAmount"></form-group>

                <div class="mt-3 d-flex align-items-center">
                    <font-awesome-icon icon="fa-brands fa-youtube" class="me-2 text-custom" size="lg" />
                    <span class="fw-400 fs-13 text-decoration-underline">အမှားအယွင်းမရှိစေရန် ငွေဖြည့်နည်း ကြည့်ပါ</span>
                </div>

                <PrimaryButton @click="next()" class="login-btn mt-auto">ဆက်သွားမည်</PrimaryButton>
            </template>
            <template v-else>
                <div class="text-center">
                    <img style="width:15vw;height: 15vw;"
                        :src="require('@/assets/payments/' + payment_options[selectedPayment].icon)" alt="">
                    <h1 class="fw-600 fs-18 mb-4 mt-4">{{ payment_options[selectedPayment].name }} <span
                            class="fw-400 fs-14">နှင််ငွေဖြည့်မည်</span>
                    </h1>
                </div>

                <div class="agents mt-2">
                    <ul class="list-group list-group-flush">
                        <li v-for="(agent, index) in agents" :key="index"
                            class="list-group-item d-flex pt-3 align-items-center">
                            <div>
                                <h1 class="fw-600 fs-16 mb-1">{{ agent.name }}</h1>
                                <p class="fw-400 fs-14 mb-0" :ref="agent.phone">{{ agent.phone }}</p>
                            </div>
                            <span class="text-custom ms-auto">
                                <font-awesome-icon icon="fa-regular fa-clone" class="me-2" />
                                <span class="fw-400 fs-13" @click="copyNumber(agent.phone)">ကူးယူမည်</span>
                            </span>
                        </li>
                    </ul>
                </div>

                <div class="mt-4">
                    <div class="fw-600 fs-14 mb-1">Transaction ID <span class="fw-400 fs-12">(နောက်ဆုံးဂဏန်း ၆ လုံး)</span>
                    </div>
                    <div class="form-group">
                        <form-group required type="text" v-model="transactionId"
                            placeholder="Enter the last 6 transaction number" :errors="errors.transaction_code">
                        </form-group>

                    </div>

                </div>

                <div class="mt-3 d-flex align-items-center">
                    <font-awesome-icon icon="fa-brands fa-youtube" class="me-2 text-custom" size="lg" />
                    <span class="fw-400 fs-13 text-decoration-underline">အမှားအယွင်းမရှိစေရန် ငွေဖြည့်နည်း ကြည့်ပါ</span>
                </div>

                <div class="mt-auto d-flex">
                    <div class="w-50 me-1">
                        <OrdinaryButton @click="step = 1" type="submit" class="login-btn mt-auto fw-700 fs-14">နောက်သို့
                        </OrdinaryButton>

                    </div>
                    <div class="w-50 ms-1">
                        <PrimaryButton type="submit" class="login-btn mt-auto fw-700 fs-14">တင်ပြမည်
                        </PrimaryButton>
                    </div>
                </div>
            </template>
        </form>
    </div>
</template>
<script>
import userService from '@/services/user.service';
export default {
    data() {
        return {
            step: 1,
            agents: [],
            payment_options: [
                {
                    name: "KBZ Pay",
                    icon: "kpay.svg",
                    type: "kpay",
                    id: 1,
                },
                {
                    name: "Wave Pay",
                    icon: "wave.svg",
                    type: "wavepay",
                    id: 2
                }
            ],
            amount_options: [
                5000, 10000, 50000, 100000, 2000000
            ],
            showWarningModal: true,
            transactionId: null,
            selectedPayment: 0,
            selectedAmount: null,
            errors: {},
        };
    },
    mounted() {
        userService.getAgents().then(response => {
            this.agents = response.data.data
        })
    },
    methods: {
        copyNumber(number) {
            const clipboardData =
                event.clipboardData ||
                window.clipboardData ||
                event.originalEvent?.clipboardData ||
                navigator.clipboard;
            clipboardData.writeText(number);
            this.$store.dispatch('showToast', 'Copied to clipboard')
        },
        applyTopUp() {
            event.preventDefault()
            this.$store.dispatch('loading')
            userService.applyTopUp({
                payment_method: this.payment_options[this.selectedPayment].id,
                amount: this.selectedAmount,
                transaction_id: this.transactionId,
            }).then((response) => {
                this.$store.dispatch('loadingComplete')
                if (response.status) {
                    this.$router.push({
                        name: "home",
                        params: {
                            type: "topup"
                        }
                    });
                }
            }).catch(({ response }) => {
                console.log(response)

                if (response.status == 422) {
                    this.errors = response.data.errors
                    if (this.errors.amount) {
                        this.step = 1
                    }
                }
            });

        },
        selectPayment(index) {
            this.selectedPayment = index;
            console.log(this.selectedPayment)
        },
        selectAmount(amount) {
            this.selectedAmount = amount;
        },
        next() {
            if (this.selectedAmount && this.selectedAmount > 0) {
                delete this.errors.amount
                this.step = 2;
                return;
            }
            this.errors = {
                amount: ['Amount field is required!']
            }
        }
    },
}
</script>
<style>
.selected-payment {
    background-color: #FFC1071A;
}

.bg-amount {
    background: #FFF0A4 !important;
    color: #000 !important;
}

.amount-input {
    background: #FFF0A4;
    border: 0;
    border-radius: .7rem;
    width: 100%;
    padding: .8rem 1.2rem;
    font-size: .85rem;
}
</style>
