import { createWebHistory, createRouter } from "vue-router";
// import IndexView from "@/views/IndexView.vue";
import LoginView from "@/views/LoginView.vue";
import RegisterView from "@/views/RegisterView.vue";
// import CreatePinView from "@/views/CreatePinView.vue";
// import ReferralView from "@/views/ReferralView.vue";
import GetStartedView from "@/views/GetStartedView.vue";
import HomeView from "@/views/HomeView.vue";

import TwoDWinnersView from "@/views/2DWinnersView.vue";
import ThreeDWinnersView from "@/views/3DWinnersView.vue";


import TopUpsView from "@/views/TopUpsView.vue";
import TopUpView from "@/views/TopUpView.vue";
import TransactionPendingView from "@/views/TransactionPendingView.vue";

import WithDrawsView from "@/views/WithDrawsView.vue";
import WithDrawInfoView from "@/views/WithdrawInfoView.vue";

// import WithDrawView from "@/views/WithDrawView.vue";
import EnterPinView from "@/views/EnterPinView.vue";

import TransactionHistoryView from "@/views/TransactionHistoryView.vue";
import TransactionDetailView from "@/views/TransactionDetailView.vue";

import ProfileView from "@/views/ProfileView.vue";
import UpdateProfileView from "@/views/UpdateProfileView.vue";

import ServicesView from "@/views/ServicesView.vue";

import TwoDBettingListView from "@/views/2DBettingListView.vue";
import ThreeDBettingListView from "@/views/3DBettingListView.vue";


import TwoDView from "@/views/2DView.vue";
import TwoDSection from "@/views/2DSection.vue";
import TwoDBettingListDetailView from "@/views/2DBettingListDetailView.vue";
import TwoDClosingDaysView from "@/views/2DClosingDaysView.vue";

import ThreeDView from "@/views/3DView.vue";
import ThreeDSection from "@/views/3DSection.vue";
import ThreeDBettingListDetailView from "@/views/3DBettingListDetailView.vue";


import NotificationsView from "@/views/NotificationsView.vue";
import PrivacyPolicyView from "@/views/PrivacyPolicyView.vue";

import middleware from "@/middleware";



const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      navbar: false,
      footerNavbar: true,
      backButton: true,

    }
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      title:"LuckyMen",
      navbar: true,
      footerNavbar: false,
      backButton: true,
      requireGuest: true,
      previous_route:'home',
      
    }
  },
  {
    path: "/register",
    name: "register",
    component: RegisterView,
    meta: {
      title:"LuckyMen",
      navbar: true,
      footerNavbar: false,
      backButton: true,
      requireGuest:true,
      previous_route:'home',
      
    }
  },
  // {
  //    path: "/new-pin",
  //    name: "new-pin",
  //    component: CreatePinView,
  //    meta: {
  //      navbar: true,
  //      footerNavbar: false,
  //     backButton: true,
       
  //    }
  // },
  // {
  //    path: "/referral",
  //    name: "referral",
  //    component: ReferralView,
  //    meta: {
  //     navbar: true,
  //      footerNavbar: false,
  //     backButton: true,
      
  //   }
  // },
  {
     path: "/get-started",
     name: "get-started",
     component: GetStartedView,
     meta: {
       navbar: false,
       footerNavbar: false,
       backButton: true,
       
    }
  },
  {
     path: "/2d/winners",
     name: "2d.winners",
     component: TwoDWinnersView, 
     meta: {
        title:"2D ထီပေါက်သူ",
        navbar: true,
        footerNavbar: true,
        backButton: true,
        previous_route:'2d',
        
     }
  },
  {
     path: "/3d/winners",
     name: "3d.winners",
     component: ThreeDWinnersView, 
     meta: {
        title:"3D ထီပေါက်သူ",
        navbar: true,
        footerNavbar: true,
        backButton: true,
        previous_route:'3d',
     }
  },
  {
    path: "/top-ups",
    name: "top-ups",
    component: TopUpsView,
    meta: {
      title:"ငွေဖြည့်မည်",
      navbar: true,
      footerNavbar: false,
      backButton: true,  
     }
  },
  {
    path: "/top-up",
    name: "top-up",
    component: TopUpView,
    meta: {
      title:"ငွေဖြည့်မည်",
      navbar: true,
      footerNavbar: false,
      backButton: true,
      requireAuth:true,

    }
  },
  {
    path: "/transaction/:type/pending",
    name: "transaction-pending",
    component: TransactionPendingView,
    meta: {
      navbar: false,
      footerNavbar: false,
      backButton: true,
      requireAuth:true,
        
     }
  },
  {
    path: "/withdraws",
    name: "withdraws",
    component: WithDrawsView,
    meta: {
      title:"ငွေထုတ်မည်",
      navbar: true,
      footerNavbar: false,
      backButton: true,
      requireAuth:true,
        
     }
  },
   {
    path: "/withdraw/info",
    name: "withdraw-info",
    component: WithDrawInfoView,
    meta: {
        title:"Withdrawal Account",
        navbar: true,
        footerNavbar: false,
        backButton: true,
        requireAuth:true,
     }
  },
  {
    path: "/withdraw",
    name: "withdraw",
    component: WithDrawsView,
    meta: {
        title:"ငွေထုတ်မည်",
        navbar: true,
        footerNavbar: false,
        backButton: true,
        requireAuth:true,
        
        
     }
  },
  {
    path: "/enter-pin",
    name: "enter-pin",
    component: EnterPinView,
    meta: {
        title:"Enter Pin",
        navbar: true,
        footerNavbar: false,
        backButton: true,
        requireAuth:true,
        
     }
  },
  {
    path: "/transaction-history",
    name: "transaction-history",
    component: TransactionHistoryView,
    meta: {
      title: "ငွေသွင်း/ငွေထုတ်မှတ်တမ်း",
      navbar: true,
      footerNavbar: false,
      backButton: true,
      requireAuth:true,
      previous_route:"back"
    }
  },
  {
    path: "/transaction/:transaction_id/detail",
    name: "transaction-detail",
    component: TransactionDetailView,
    meta: {
      title: "ငွေသွင်း/ငွေထုတ်မှတ်တမ်း",
      navbar: true,
      footerNavbar: false,
      backButton: true,
      requireAuth:true,

    }
  },
  {
    path: "/services",
    name: "services",
    component: ServicesView,
    meta: {
      title: "Service ဖုန်းနံပါတ်များ",
      navbar: true,
      footerNavbar: true,
      backButton: false,
      
    }
  },
  {
    path: "/profile",
    name: "profile",
    component: ProfileView,
    meta: {
      title: "ကျွန်ုပ်၏အချက်အလက်",
      navbar: true,
      footerNavbar: true,
      backButton: false,
      requireAuth:true,
      
    }
  },
  {
    path: "/profile/update",
    name: "profile.update",
    component: UpdateProfileView,
    meta: {
      title: "ကျွန်ုပ်၏အချက်အလက်",
      navbar: true,
      footerNavbar: false,
      backButton: true,
      requireAuth:true,
      
    }
  },
   {
    path: "/2d",
    name: "2d",
    component: TwoDView,
    meta: {
      navbar: true,
      footerNavbar: false,
      backButton: true,
      title: "2D ထိုးမည်",
      requireAuth:true,
      previous_route:'home',
    }
  },
   {
    path: "/2d/section/:section",
    name: "2d-section",
    component: TwoDSection,
     meta: {
      //  title:"2D",
      navbar: true,
      footerNavbar: false,
       backButton: true,
      requireAuth:true,
      previous_route:'2d',
      
    }
  },
  {
    path: "/3d/section/:section",
    name: "3d-section",
    component: ThreeDSection,
     meta: {
      navbar: true,
      footerNavbar: false,
      backButton: true,
      requireAuth:true,
      previous_route:'3d',
    }
  },
  // {
  //   path: "/betting/list",
  //   name: "betting-list",
  //   component: BettingListView,
  //    meta: {
  //     title:"ထိုးမှတ်တမ်း",
  //     navbar: true,
  //     footerNavbar: false,
  //     backButton: true,
  //   }
  // },
  {
    path: "/2d/list",
    name: "2d-betting-list",
    component: TwoDBettingListView,
     meta: {
      title:"2D မှတ်တမ်း",
      navbar: true,
      footerNavbar: false,
      backButton: true,
      requireAuth:true,
      previous_route:'back',
      
    }
  },
  {
    path: "/3d/list",
    name: "3d-betting-list",
    component: ThreeDBettingListView,
     meta: {
      title:"3D မှတ်တမ်း",
      navbar: true,
      footerNavbar: false,
      backButton: true,
      requireAuth:true,
      previous_route:'back',
    }
  },
  {
    path: "/2d/bet/list/:date",
    name: "2d-betting-list-detail",
    component: TwoDBettingListDetailView,
     meta: {
      title:"2D ထိုးမှတ်တမ်း",
      navbar: true,
      footerNavbar: false,
      backButton: true,
      requireAuth:true,
      previous_route:'back',
    },
  },
  {
    path: "/3d/bet/list/:date",
    name: "3d-betting-list-detail",
    component: ThreeDBettingListDetailView,
     meta: {
      title:"3D ထိုးမှတ်တမ်း",
      navbar: true,
      footerNavbar: false,
       backButton: true,
      requireAuth:true,
      previous_route:'3d',
      
    },
  },
  {
    path: "/2d/closing-days",
    name: "2d-closing-days",
    component: TwoDClosingDaysView,
     meta: {
      title:"ထီပိတ်ရက်များ",
      navbar: true,
      footerNavbar: false,
      backButton: true,
      previous_route:'2d',
    }
  },
  {
    path: "/notifications",
    name: "notifications",
    component: NotificationsView,
     meta: {
      title:"အသိပေးချက်များ",
      navbar: true,
      footerNavbar: false,
      backButton: true,
      requireAuth:true,
      previous_route:'back',
    }
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicyView,
     meta: {
      title:"Privacy Policy",
      navbar: true,
      footerNavbar: false,
      backButton: true,
      previous_route:'home',
    }
  },


  {
    path: "/3d",
    name: "3d",
    component: ThreeDView,
    meta: {
      title:"3D ထိုးမည်",
      navbar: true,
      footerNavbar: false,
      backButton: true,
      requireAuth:true,
      previous_route:'home',

    }
  },
  
  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
middleware(router)


export default router;