<template>
    <button class="btn font-poppins btn-outline-custom outline-btn text-custom w-100">
        <slot></slot>
    </button>
</template>
<style>
.outline-btn {
    border-width: 2px;
    background-color: #F5F5F5;
    border-radius: 10px;
    color: #000 !important;
}

.outline-btn:focus {
    background-color: #F5F5F5 !important;
}
</style>