<template>
    <div class="px-3 pb-2">
        <div v-if="mode == 'bet_list'" class="row mb-4 mt-3">
            <div class="col-6 pe-1">
                <PrimaryButton class="text-dark">ထိုးမှတ်တမ်း</PrimaryButton>
            </div>
            <div class="col-6 ps-1">
                <SecondaryButton @click="mode = 'number_list'">ထီပေါက်စဉ်မှတ်တမ်း</SecondaryButton>

            </div>
        </div>
        <div v-else class="row mb-4 mt-3">
            <div class="col-6 pe-1">
                <SecondaryButton @click="mode = 'bet_list'">ထိုးမှတ်တမ်း</SecondaryButton>
            </div>
            <div class="col-6 ps-1">
                <PrimaryButton class="text-dark">ထီပေါက်စဉ်မှတ်တမ်း</PrimaryButton>

            </div>
        </div>
        <template v-if="mode == 'bet_list'">
            <div class="d-flex">
                <div class="w-50 text-start text-secondary fw-600 fs-12">ရက်စွဲ</div>
                <div class="w-50 text-end  text-secondary fw-600 fs-12 me-4">စုစုပေါင်းထိုးငွေ</div>
            </div>
            <ul class="list-group list-group-flush">
                <router-link :to="{
                    name: '2d-betting-list-detail', params: {
                        date: item.date
                    }
                }" v-for="(item, index) in bet_lists" :key="index"
                    class="list-group-item py-3 d-flex align-items-center">
                    <h1 class="fw-600 fs-16 mb-0">{{ item.date }}</h1>
                    <p class="ms-auto me-4 fw-400 fs-16 mb-0">{{ $helper.formattedNumber(item.total_amount) }} Ks</p>
                    <font-awesome-icon icon="fa-solid fa-chevron-right" />
                </router-link>
            </ul>
        </template>
        <template v-else>
            <div class=" px-3">
                <div v-for="(dates, index) in histories" :key="index" class="py-3">
                    <h1 class="fw-600 fs-16 text-start">{{ index }}</h1>
                    <div v-for="(times, i) in dates" class="border-bottom py-3  " :key="i">
                        <p class="mb-0 fw-600 fs-14 mb-2 text-center">{{ i }}</p>
                        <div class="row text-center justify-content-center align-items-center mt-2"
                            v-for="(history, j) in times" :key="j">
                            <template v-if="history.has_set_value">
                                <div class="col-4">
                                    <p class="mb-0 fw-500 fs-12">Set</p>
                                    <span class="fw-700 text-danger fs-14">{{ history.set || '.....' }}</span>
                                </div>
                                <div class="col-4">
                                    <p class="mb-0 fw-500 fs-12">Value</p>
                                    <span class="fw-700 text-danger fs-14">{{ history.set || '.....' }}</span>

                                </div>
                            </template>
                            <template v-else>
                                <div class="col-8">
                                    <p class="mb-0 fw-500 fs-12">{{ history.title }}</p>

                                </div>
                            </template>
                            <div class="col-4">
                                <p class="mb-0 fw-500 fs-12">Number</p>
                                <span class="fw-700 text-danger fs-18">{{ history.number || '??' }}</span>
                            </div>
                            <!-- <span
                                    class="fw-700 bg-danger text-white rounded-circle mx-auto fs-16 d-flex align-items-center justify-content-center"
                                    style="width: 35px;height: 35px;">{{
                                        history.number || '??' }}</span> -->
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import SecondaryButton from '@/components/buttons/SecondaryButton.vue';
import userService from '@/services/user.service';

export default {
    data() {
        return {
            bet_lists: [],
            histories: [],
            mode: 'bet_list'
        };
    },
    mounted() {
        this.$store.dispatch('loading');
        userService.getUser2DBettingHistory().then(({ data }) => {
            console.log(data.data);
            this.bet_lists = data.data;
            this.histories = data.histories
            console.log(this.histories)
            this.$store.dispatch('loadingComplete');
        });
    },
    components: { SecondaryButton }
}
</script>
<style>
.winners-nav {
    background-color: #FFC107;
    text-align: center;
    color: white;
    outline: none;
    border: none;
}

.winner-nav-item {
    padding: 10px 0px;
    color: #fff;
    text-decoration: none;
}

.winner-nav-item.active {
    background-color: #FFC107;
}
</style>