export default {
    formatValue(value) {
        var number, amount, total_amount, numbers;
        if (value.includes('R')) {
            let pattern = new RegExp('^[0-9]{3}[R][0-9]*$');
            if (pattern.test(value) && !(value[0] == value[1] && value[1] == value[2] && value[0] == value[2])) {
                number = value[0] + value[1] + value[2] + 'R';
                amount = parseInt(value.split('R')[1]);
                numbers = this.getNumbers(number, amount);
            }

        }
        else if (value.includes('T')) {
            let pattern = new RegExp(/^T\d+$/);
            if (pattern.test(value)) {
                number = 'T';
                amount = parseInt(value.split('T')[1]);
                numbers = this.getNumbers(number, amount);
            }

        }
        else if (value.includes('*')) {
            let pattern = new RegExp(/^[0-9*]{5,}$/);
            if (pattern.test(value)) {
                number = value[0].toString() + value[1].toString() + value[2].toString();
                amount = parseInt(value.split(number)[1]);
                numbers = this.getNumbers(number, amount);
            }

        }
        else {
            let pattern = new RegExp(/^\d+$/);
            if (pattern.test(value)) {
                number = value[0] + value[1] + value[2];
                amount = parseInt(value.toString().substring(3, value.length));
                total_amount = amount
            }
        }


        if (number && amount) {
            numbers = this.getNumbers(number, amount)
            total_amount = amount * numbers.length
            return {
                number, amount, total_amount, numbers
            };
        } else {
            return false;
        }

    },

    hasTargetedCharacterAppearedTwice(str, targetedChar) {
        // Initialize a count to keep track of how many times the targetedChar appears
        let count = 0;
      
        // Loop through the characters in the string
        for (const char of str) {
            // Check if the current character is the targeted character
            if (char === targetedChar) {
                // Increment the count each time the targeted character is found
                count++;
      
                // If the count reaches 2, return true
                if (count === 2) {
                    return true;
                }
            }
        }
      
        // If the count is less than 2, the targeted character did not appear at least twice
        return false;
    },

    getNumbers(number, amount) {
        let data = [];

        if (number.includes('R')) {
            number = number.split('R')[0]

            let excludedNumbers = '0123456789';
            for (let n in number.split('')) {
                excludedNumbers = excludedNumbers.substring(n, '');
            }

            for (let i = 0; i <= 999; i++) {
                let num = i.toString().padStart(3, '0');
                if (
                    [...num].every(digit => number.includes(digit)) &&
                    [...number].every(digit => num.includes(digit))
                ) {
                    if (number[0] == number[1]) {
                        if (this.hasTargetedCharacterAppearedTwice(num, number[0])) {
                            data.push({
                                number: num,
                                amount: amount
                            })
                        }
                    } else if (number[1] == number[2]) {
                        if (this.hasTargetedCharacterAppearedTwice(num, number[1])) {
                            data.push({
                                number: num,
                                amount: amount
                            })
                        }
                    } else if (number[0] == number[2]) {
                        if (this.hasTargetedCharacterAppearedTwice(num, number[2])) {
                            data.push({
                                number: num,
                                amount: amount
                            })
                        }
                    } else {
                        data.push({
                            number: num,
                            amount: amount
                        })
                    }


                    
                    
                }
            }
            
        }
        else if (number.includes('T')) {
            for (let i = 0; i <= 999; i++) {
                let num = i.toString().padStart(3, '0');
                if (num[0] == num[1] && num[1] == num[2]) {
                    data.push({
                        number: num,
                        amount: amount
                    })
                }
            }
        }
        else if (number.includes('*')) {
            for (let i = 0; i <= 9; i++) {
                data.push({
                    number: number.replace('*', i),
                    amount: amount
                })
            }
        }
        return data;
    }
}