<template>
    <div v-if="transaction" class="p-4 d-flex flex-column transaction-detail">
        <div class="rounded-4 header p-3 bg-white text-center">
            <div class="mb-2">
                <img src="@/assets/success.svg" style="width: 15%;" class="" alt="">
                <div v-if="transaction.condition">
                    <span class="fs-12">{{ transaction.condition }}</span>

                </div>
            </div>

            <h1 class="fw-600 fs-18 mb-1">
                {{ transaction.type + " " + $helper.formattedNumber(transaction.amount) }} Ks</h1>
        </div>
        <div class="line mx-4"></div>
        <div class="rounded-4 body p-3 bg-white">
            <!-- <h1 class="fw-600 fs-14 my-3">Transaction Detail</h1> -->
            <div class="row" v-if="transaction.transaction_type">
                <div class="col-6">
                    <p class="fw-400 fs-12">အမျိုးအစား</p>
                </div>
                <div class="col-6 text-end">
                    <p class="fw-400 fs-14">{{ transaction.transaction_type }}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <p class="fw-400 fs-12">အချိန်</p>
                </div>
                <div class="col-6 text-end">
                    <p class="fw-400 fs-14">{{ $helper.formattedDateTime(transaction.created_at) }}</p>
                </div>
            </div>
            <div class="row" v-if="transaction.transaction_id">
                <div class="col-6">
                    <p class="fw-400 fs-12">လုပ်ဆောင်မှုအမှတ်စဉ်</p>
                </div>
                <div class="col-6 text-end">
                    <p class="fw-400 fs-14">{{ transaction.transaction_id }}</p>
                </div>
            </div>
            <div class="row" v-if="transaction.payment">
                <div class="col-6">
                    <p class="fw-400 fs-12">ဘဏ်အမျိုးအစား</p>
                </div>
                <div class="col-6 text-end">
                    <p class="fw-400 fs-14">{{ transaction.payment }}</p>
                </div>
            </div>

            <div class="row" v-if="transaction.title">
                <div class="col-6">
                    <p class="fw-400 fs-12">အကြောင်းအရင်း</p>
                </div>
                <div class="col-6 text-end">
                    <p class="fw-400 fs-14">{{ transaction.title }}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <p class="fw-400 fs-12">Amount</p>
                </div>
                <div class="col-6 text-end">
                    <p class="fw-400 fs-14">{{ $helper.formattedNumber(transaction.amount) }} Ks</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import userService from '@/services/user.service';

export default {
    data() {
        return {
            transaction_id: this.$route.params.transaction_id,
            transaction: null
        }
    },
    mounted() {
        this.fetchTransaction()
    },
    watch: {
        transaction_id() {
            this.fetchTransaction();
        }
    },
    methods: {
        fetchTransaction() {
            console.log(this.transaction_id)
            if (this.transaction_id) {
                this.$store.dispatch('loading')
                userService.getTransactionDetail(this.transaction_id).then(response => {
                    console.log(response)
                    this.transaction = response.data.data

                    this.$store.dispatch('loadingComplete')
                })
            }
        }
    }
}
</script>
<style>
.transaction-detail {
    background-color: #F5F5F5;
}

.transaction-detail .line {
    border-top: 2px dashed #D3D8E7;
}

.transaction-detail img {
    width: 12vw;
}
</style>