<template>
    <button :type="type" :class="{ 'text-dark': text == 'dark', 'text-danger': text == 'danger' }"
        class="btn font-poppins fw-600 rounded-3 fs-14 primary-btn btn-primary w-100">
        <slot></slot>
    </button>
</template>
<script>
export default {
    props: {
        type: {
            type: String,
            default: 'button'
        },
        text: {
            type: String,
            default: "dark"
        }
    }
}
</script>
<style>
.primary-btn {
    padding: .6rem 0 !important;
    background-color: #FFC107 !important;
    border-color: #FFC107 !important;
    outline-color: #FFC107 !important;
    border: 2px solid #FFC107 !important;
    /* border: 0 !important; */
    color: #000;
}
</style>