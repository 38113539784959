import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import helper2d from "./helpers/2d";
import helper3d from "./helpers/3d";
import helper from './helpers/helper'
import store from "./store";


import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fas)
library.add(far)
library.add(fab)





import PrimaryButton from './components/buttons/PrimaryButton.vue';
import SecondaryButton from './components/buttons/SecondaryButton.vue';
import OrdinaryButton from './components/buttons/OrdinaryButton.vue';
import OutlineButton from './components/buttons/OutlineButton.vue';

import ReferrIcon from './components/ReferrIcon.vue';
import FormGroup from './components/FormGroup.vue';
import FooterNavbar from './components/FooterNavbar.vue';

const app = createApp(App)
app.config.globalProperties.$helper = helper
app.config.globalProperties.$2d = helper2d
app.config.globalProperties.$3d = helper3d



app.component('font-awesome-icon', FontAwesomeIcon)
app.component('PrimaryButton', PrimaryButton)
app.component('OutlineButton', OutlineButton)
app.component('OrdinaryButton', OrdinaryButton)
app.component('SecondaryButton', SecondaryButton)

app.component('ReferrIcon', ReferrIcon)
app.component('FormGroup', FormGroup)
app.component('FooterNavbar',FooterNavbar)

app.use(router)
app.use(store)

app.directive('no-scroll-on-focus', {
  mounted(el) {
    el.dataset.scrollY = 0;

    el.addEventListener('focus', function () {
      el.dataset.scrollY = window.scrollY;
      document.body.style.overflow = 'hidden';
    });

    el.addEventListener('blur', function () {
      document.body.style.overflow = 'auto';
      window.scrollTo(0, el.dataset.scrollY || 0);
    });
  },
});

app.mount('#app')
