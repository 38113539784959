<template>
    <div class="p-4">
        <form @submit="handleRegister()" class=" h-100">

            <template v-if="step == 1">
                <h1 class="title fs-18 text-center">အကောင့်အသစ်ဖွင့်မည်</h1>

                <div>

                    <FormGroup v-model="phone" :errors="errors.phone" label="ဖုန်းနံပါတ်" placeholder="09ပါရိုက်ထည့်ပါ"
                        type="number">
                    </FormGroup>
                    <FormGroup :required="true" v-model="name" :errors="errors.name" label="အမည်" placeholder="အမည်"
                        type="text">
                    </FormGroup>
                </div>
                <PrimaryButton @click="nextStep()" class="login-btn mt-2 text-dark">ဆက်သွားမည်</PrimaryButton>
                <hr>
                <router-link :to="{ name: 'login' }">
                    <SecondaryButton class="">အကောင့်ဝင်ရန်</SecondaryButton>
                </router-link>
            </template>
            <template v-else>
                <h1 class="title mb-3 text-center">စကားဝှက်ထည့်ရန်</h1>


                <div>
                    <FormGroup v-model="password" min="8" :errors="errors.password" label="စကားဝှက်"
                        placeholder="စကားဝှက်ရိုက်ထည့်ပါ" type="password">
                    </FormGroup>
                    <FormGroup v-model="password_confirmation" label="စကားဝှက်ကိုအတည်ပြုပါ"
                        placeholder="စကားဝှက်ရိုက်ထည့်ပါ" type="password">
                    </FormGroup>
                </div>

                <PrimaryButton type="submit" class="login-btn text-dark mt-2">Register</PrimaryButton>
                <SecondaryButton class="mt-2" @click="step = 1">ရှေ့သို့</SecondaryButton>


            </template>

        </form>
    </div>
</template>
<script>
import SecondaryButton from '@/components/buttons/SecondaryButton.vue';

export default {
    data() {
        return {
            step: 1,
            OTPSeconds: 60,
            OTPLabel: "Get OTP",
            previewImage: null,
            phone: "",
            name: "",
            password: "",
            password_confirmation: "",
            image: "",
            errors: {},
        };
    },
    methods: {
        nextStep() {

            if (this.phone && this.name) {
                this.step = 2
            } else {
                this.$store.dispatch('showError', 'ဖုန်းနံပါတ် နင့် အမည်ထည့်ပါ')
            }
        },
        getOTP() {
            var otpInterval = setInterval(() => {
                this.OTPSeconds--;
                if (this.OTPSeconds <= 0) {
                    clearInterval(otpInterval);
                    this.OTPSeconds = 60;
                    this.OTPLabel = "Get OTP";
                }
                this.OTPLabel = this.OTPSeconds + "s";
            }, 1000);
        },
        chooseImage() {
            this.$refs.chooseImage.click();
        },
        imageChoosed(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.previewImage = e.target.result;
            };
            this.image = e.target.files[0];
        },
        handleRegister() {
            event.preventDefault();
            let data = new FormData();
            data.append('image', this.image);
            data.append('phone', this.phone);
            data.append('name', this.name);
            data.append('password', this.password);
            data.append('password_confirmation', this.password_confirmation);
            this.$store.dispatch('loading');
            this.$store.dispatch('auth/register', data).then((data) => {
                this.$store.dispatch('loadingComplete');
                if (data.success) {
                    this.$router.push({ name: 'home' });
                }
            }).catch(({ response }) => {
                if (response.status == 422) {
                    this.errors = response.data.errors;
                    if (this.errors.phone && this.errors.phone.length > 0) {
                        this.step = 1;
                    }
                }
            });
        }
    },
    components: { SecondaryButton }
}
</script>

<style>
.profile-image {
    width: 100px;
    height: 100px;
    background-color: #EBF0FD;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.get-otp {
    font-weight: 800;
    position: relative;
    top: 7px;
}
</style>
