

<template>
    <div class="profile ">
        <div class="p-2 px-3 bg-custom" style="height: 130px;">
            <div class="d-flex bg-white border rounded align-items-center user-info text-dark p-3" style="">
                <div>
                    <img :src="currentUser.image" class="rounded-circle" width="40" height="40" alt="">
                </div>
                <div class="text-dark text-start ms-2">
                    <h1 class="user-name mb-1">{{ currentUser.name }}</h1>
                    <p class="user-phone fs-14 text-dark mb-0">{{ currentUser.phone }}</p>
                </div>
                <router-link :to="{ name: 'profile.update' }" class=" ms-auto">
                    <div class="user-info-edit bg-custom rounded-circle">
                        <font-awesome-icon class="text-danger" size="sm" icon="fa-solid fa-pencil"></font-awesome-icon>
                    </div>
                </router-link>
            </div>

        </div>
        <!-- <div class="banner bg-custom" v-if="logined">
            <div class="banner-icon">
                <font-awesome-icon icon="fa-solid fa-user" size="xl" class="text-custom"></font-awesome-icon>
            </div>
            <h1 class="fw-600 fs-14 text-white mt-3 mb-0">{{ currentUser.name }}</h1>
            <p class="fw-500 fs-12 phone">{{ currentUser.phone }}</p>
        </div>
        <div v-else class="banner">
            <div class="banner-icon">
                <font-awesome-icon icon="fa-solid fa-user" size="xl" class="text-custom"></font-awesome-icon>
            </div>
            <p class="fw-600 fs-13 text-white mt-4 mb-3">အကောင့်ဖွင့်ပါ (သို့) Log in ဝင်ပါ</p>
        </div> -->
        <div class="p-3 bg-white" style="border-top-left-radius: 1rem;border-top-right-radius: 1rem;margin-top: -30px;">
            <div class="d-flex" v-if="!logined">
                <router-link :to="{ name: 'login' }" class="w-50">
                    <SecondaryButton class="fw-600 fs-14 me-1 w-50">Login</SecondaryButton>
                </router-link>
                <router-link :to="{ name: 'register' }" class="w-50">
                    <PrimaryButton class="fw-600 fs-14 ms-1">Register</PrimaryButton>
                </router-link>
            </div>
            <div class="px-2">
                <!-- <h1 class="fw-700 fs-16 mb-0">ဆက်တင်များ</h1> -->
                <div class="row align-items-center" v-if="logined">

                    <div class="col-1 d-flex justify-content-center">
                        <font-awesome-icon icon="fa-solid fa-bell" size="lg" class="text-custom" />
                    </div>

                    <div class="col-11 my-3">
                        <router-link :to="{ name: 'notifications' }" class="w-100 text-dark d-flex text-decoration-none">
                            <span class="fw-600 fs-14 ">အသိပေးချက်များ</span>
                            <font-awesome-icon icon="fa-solid fa-angle-right" size="lg" class="ms-auto" />
                        </router-link>
                    </div>

                    <div class="col-1 d-flex justify-content-center">
                        <font-awesome-icon icon="fa-solid fa-rectangle-list" size="lg" class="text-custom" />
                    </div>

                    <div class="col-11 my-3">
                        <router-link :to="{ name: '2d-betting-list' }" class="w-100 text-dark d-flex text-decoration-none">
                            <span class="fw-600 fs-14 ">2D ထိုးမှတ်တမ်း</span>
                            <font-awesome-icon icon="fa-solid fa-angle-right" size="lg" class="ms-auto" />
                        </router-link>
                    </div>

                    <div class="col-1 d-flex justify-content-center">
                        <font-awesome-icon icon="fa-solid fa-rectangle-list" size="lg" class="text-custom" />
                    </div>

                    <div class="col-11 my-3">
                        <router-link :to="{ name: '3d-betting-list' }" class="w-100 text-dark d-flex text-decoration-none">
                            <span class="fw-600 fs-14 ">3D ထိုးမှတ်တမ်း</span>
                            <font-awesome-icon icon="fa-solid fa-angle-right" size="lg" class="ms-auto" />
                        </router-link>
                    </div>

                    <div class="col-1 d-flex justify-content-center">
                        <font-awesome-icon icon="fa-solid fa-circle-dollar-to-slot" size="lg" class="text-custom" />
                    </div>

                    <div class="col-11 my-3">
                        <router-link :to="{ name: 'transaction-history' }"
                            class="w-100 text-dark d-flex text-decoration-none">
                            <span class="fw-600 fs-14 ">ငွေသွင်း/ငွေထုတ်မှတ်တမ်း</span>
                            <font-awesome-icon icon="fa-solid fa-angle-right" size="lg" class="ms-auto" />
                        </router-link>
                    </div>

                    <!-- <div class="col-1 d-flex justify-content-center">
                        <font-awesome-icon icon="fa-solid fa-user-plus" size="lg" class="text-custom" />
                    </div> -->

                    <!-- <div class="col-11 my-3 d-flex align-items-center">
                        <router-link :to="{ name: 'home' }" class="w-100 text-dark d-flex flex-column text-decoration-none">
                            <span class="fw-600 fs-14 ">မိတ်ဆက်ကုပ်</span>
                            <span class="fw-400 fs-12 text-custom">Code from: Zay Ya</span>
                        </router-link>
                        <font-awesome-icon icon="fa-solid fa-angle-right" size="lg" class="ms-auto" />

                    </div> -->
                </div>
                <!-- <div class="row align-items-center">
                    <div class="col-1 d-flex justify-content-center">
                        <font-awesome-icon icon="fa-solid fa-file-lines" size="lg" class="text-custom" />
                    </div>

                    <div class="col-11 my-3">
                        <router-link :to="{ name: 'privacy-policy' }" class="w-100 text-dark d-flex text-decoration-none">
                            <span class="fw-600 fs-14 ">ကိုယ်ရေးအချက်အလက်မူဝါဒ</span>
                            <font-awesome-icon icon="fa-solid fa-angle-right" size="lg" class="ms-auto" />
                        </router-link>
                    </div>
                </div> -->
                <div class="col-12">
                    <SecondaryButton @click="showConfirmationModal = true" class="text-danger">
                        <font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket" size="lg" class="text-danger me-1" />

                        Logout
                    </SecondaryButton>
                </div>
                <div class="text-center mt-4 d-flex flex-column">
                    <span class="fw-600 fs-14 ">ယခုဗားရှင်း</span>
                    <span class="fw-400 fs-12 text-danger">Version 1.0.0</span>
                </div>
            </div>
        </div>
        <confirmation-box @cancel="showConfirmationModal = false" @ok="logOut" :show="showConfirmationModal"
            title="အကောင့်ထွက်မည်" subtitle="အကောင့်ထွက်မှာ သေချာပါသလား" icon="fa-solid fa-arrow-right-from-bracket"
            cancelButtonText="မသေချာပါ" okButtonText="ထွက်မည်" />
    </div>
</template>
<script>
import ConfirmationBox from '@/components/ConfirmationBoxComponent.vue';
export default {
    components: { ConfirmationBox },
    data() {
        return {
            showConfirmationModal: false,
            logined: this.$store.state.auth.status.loggedIn,
            currentUser: this.$store.state.auth.user
        }
    },
    methods: {
        logOut() {
            this.$store.dispatch('auth/logout');
            this.$router.push('/login');
        }
    }
}
</script>
<style scoped>
.profile .banner {
    /* background: url('../assets/home-banner.png'); */

    height: 21vh;
    position: relative;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0px 0px 1rem 1rem;
}

.banner .phone {
    color: #FFFFFFCC;
}

.profile .banner-icon {
    width: 18vw;
    height: 18vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 50%;
}
</style>