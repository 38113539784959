import { createStore } from "vuex";
import { auth } from "./auth.module";
import { index } from "./index.module";


const store = createStore({
  modules: {
    auth,
    index
  },
});

export default store;