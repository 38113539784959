<template>
    <div class="mx-auto" style="max-width: 450px;position: relative;">
    <nav class="nav" v-if="navbar" style="position: fixed;top:0;width: 100%;max-width: 450px;z-index: 1;">
      <div class="d-flex w-100 align-items-center justify-content-center" style="height: 50px;">
          <div v-if="backButton" @click="back()" class="border border-2 border-danger rounded-circle text-danger d-flex align-items-center justify-content-center" style="left:1rem;position: absolute;width: 2rem;height: 2rem;z-index: 50;">
          <font-awesome-icon 
            class="go-back-button text-danger p-2" icon="fa-solid fa-chevron-left" />
        </div>
        <p class="fw-600 fs-14 text-dark mb-0">{{ $route.meta.title }}</p>
      </div>
      
    </nav>
    <div class="bg-white hide-scrollbar" :class="{'main-view' : this.$route.name != 'index' && this.$route.name != 'home'}" style="position: relative;z-index: 10;overflow-y: auto;" @toast="toast"  :style="{
      height: viewHeight, top: top, paddingBottom:paddingBottom
    }" >
      <router-view/>
    </div>
    <footer-navbar v-if="footerNavbar"></footer-navbar>
    <alert :message="alertMessage"/>
    <toast :message="toastMessage"/>
    <loading :loading="loading"></loading>
     <div style="display: none;">
      <img :src="require('./assets/logos/loading.png')">
    </div>
  </div>
</template>

<script>

import Alert from './components/AlertComponent.vue';
import Toast from './components/ToastComponent.vue';

import Loading from './components/LoadingComponent.vue';

  export default {
    name: 'App',
    components: {
      Alert,
      Loading,
      Toast
    },
    data() {
      return {
        navbar: true,
        footerNavbar: true,
        backButton: true,
        showAlert: false,
        alertMessage: null,
        toastMessage: null,
      }
    },
    mounted() {
      this.$store.dispatch('getHomeBanner')
      this.$store.dispatch('getPaymentMethods')
      this.$store.dispatch('getServices')
      this.$store.dispatch('showError',)

    },
    computed: {
      viewHeight() {
        return this.$route.name != 'index' && this.$route.name != 'home' ? "calc(100vh - 30px)" : "100vh"; 
      },
      paddingBottom() {
        return this.footerNavbar ? "70px" : "0px";
      },
      top() {
        return this.$route.name != 'index' && this.$route.name != 'home' ? "50px" : "0px";
      },
      loading() {
        return this.$store.state.index.loading
      },
    },
  methods: {
    back() {
      if (!this.$route.meta.previous_route) {
        this.$router.push({ name: 'home' })
      }
      else if (this.$route.meta.previous_route == 'back') {
        this.$router.go('-1')
      } else {
        this.$router.push({ name: this.$route.meta.previous_route })
      }
    },
    alert(message) {
      this.alertMessage = message
      setTimeout(() => {
          this.alertMessage = null
          this.$store.state.index.errorMessage = null
        }, 2000)
      }
    },
    watch: {
      '$route': {
        immediate: true,
        handler() {
          this.navbar = this.$route.meta.navbar
          this.footerNavbar = this.$route.meta.footerNavbar
          this.backButton = this.$route.meta.backButton
        }
      },
      '$store.state.index.errorMessage': {
        immediate: true,
        handler() {
          let message = this.$store.state.index.errorMessage
          if (message) {
            this.alert(message)
          }
        }
      },
      '$store.state.index.toastMessage': {
        immediate: true,
        handler() {
          let message = this.$store.state.index.toastMessage
          if (message) {
            this.toastMessage = message
            setTimeout(() => {
              this.toastMessage = null
              this.$store.state.index.toastMessage = null
            }, 2000)
          }
      }
    }
  }

}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Myanmar&display=swap');
#app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body{
  background-color: #9e9e9e !important;
}
.btn-outline-custom{
  border-color: #FFC107 !important;
}

.horizontal-line {
  border: 1px solid #FFF0A4;
}

.go-back-button {
  left: 0;
}

.text-custom{
  color: #FFC107 !important;
}

.bg-custom{
  background-color: #FFC107 !important;
}

.bg-custom2{
  background-color: #FFF0A4 !important;
}

.border-custom{
  border-color: #FFC107 !important;
  border-width: 2px;
  border-style: solid;
}

.border-custom-bottom{
  border-bottom-color: #FFC107 !important;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}

.nav {
  background-color: #FFC107;
  height: 50vh;
  z-index: 2;
}

.font-poppins {
  font-family: 'Poppins', sans-serif !important;
}

.font-noto {
  font-family: 'Noto Sans Myanmar', sans-serif !important;
}

.title {
  font-weight: 600;
  font-size: 20px;
  font-family: 'Poppins', sans-serif !important;
}

.subtitle {
  font-weight: 400;
  font-size: 13px;
  font-family: 'Noto Sans Myanmar', sans-serif !important;
}

label {
  font-weight: 600;
  font-size: 14px;
  font-family: 'Poppins', sans-serif !important;
}

.sub-label {
  font-weight: 400;
  font-size: 14px;
  font-family: 'Noto Sans Myanmar', sans-serif !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fs-11 {
  font-size: .55rem !important;
}

.fs-12 {
  font-size: .75rem !important;
}

.fs-13 {
  font-size: .8125rem !important;
}

.fs-14 {
  font-size: .875rem !important;
}

.fs-16 {
  font-size: 1rem !important;
}

.fs-17{
  font-size: 1.0625rem !important;
}

.fs-18 {
  font-size: 1.125rem !important;
}

.fs-24 {
  font-size: 1.5rem !important;
}

.fs-36 {
  font-size: 2.25rem !important;
}

.main-view{
  background: #fff;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
}
body{
  overflow-y: hidden !important;
}

.hide-scrollbar::-webkit-scrollbar{
  display: none;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
</style>
