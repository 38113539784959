

<template>
    <div class="profile">

        <div class="p-4">
            <h1 class="fw-700 fs-16 mb-3 text-center">{{ $store.state.index.serviceBannerText }}</h1>
            <ul class="list-group list-group-flush">
                <li v-for="(help_type, index) in $store.state.index.services" :key="index" class="list-group-item py-4">
                    <div v-if="index == 1" class="d-flex align-items-center mb-3">
                        <img src="../assets/services/viber.png" style="width: 40px;" class="me-2" alt="">
                        <span class="fs-14 fw-bold">Viber ဖြင့်ဆက်သွယ်ရန်</span>
                    </div>
                    <div v-else-if="index == 2" class="d-flex align-items-center mb-3">
                        <img src="../assets/services/telegram.png" style="width: 40px;" class="me-2" alt="">
                        <span class="fs-14 fw-bold">Telegram ဖြင့်ဆက်သွယ်ရန်</span>
                    </div>
                    <div v-else-if="index == 3" class="d-flex align-items-center mb-3">
                        <img src="../assets/services/phone-call.png" style="width: 40px;" class="me-2" alt="">
                        <span class="fs-14 fw-bold">Mobile ဖြင့်ဆက်သွယ်ရန်</span>
                    </div>
                    <div v-for="(help, i) in help_type" :key="i" class="mb-2 d-flex align-items-center">
                        <div>
                            <p class="fw-400 fs-12 mb-1 text-capitalize">{{ help.name }}</p>
                            <h1 class="fw-600 fs-14 mb-0">{{ help.phone }}</h1>
                        </div>
                        <font-awesome-icon icon="fa-solid fa-phone" size="lg" class="me-3 ms-auto text-custom" />

                    </div>
                    <!-- <font-awesome-icon v-if="help.type == 'viber'" icon="fa-brands fa-viber" size="lg"
                        class="me-3 text-custom" />
                    <font-awesome-icon v-else icon="fa-solid fa-phone" size="lg" class="me-3 text-custom" />

                  
                    -->
                </li>
            </ul>
        </div>
    </div>
</template>
<script>

export default {
    data() {
        return {

        }
    },
}
</script>
<style scoped>
.profile .banner {
    background: url('../assets/home-banner.png');
    height: 21vh;
    position: relative;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0px 0px 1rem 1rem;
}

.banner .phone {
    color: #FFFFFFCC;
}

.profile .banner-icon {
    width: 18vw;
    height: 18vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 50%;
}

.banner marquee {
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    background-color: #447BE833;
}
</style>