<template>
    <div class="p-4">
        <form @submit="applyTopUp()" class="d-flex flex-column h-100">
            <template v-if="step == 1">
                <label for="payment_method" class="mb-2">ငွေသွင်းမည့်အမျိုးအစား</label>
                <div class="options d-flex p-0 mb-4">
                    <div @click="selectPayment(key)" class="col-6" :class="{
                        'ps-0': (key + 1 % 2 != 0),
                        'pe-0': (key + 1 % 2 == 0),
                    }" v-for="(value, key) in payment_options" :key="key">
                        <div :class="{
                            'border-custom': key == selectedPayment,
                            'border border-2': key != selectedPayment,
                            'me-1': key % 2 == 0,
                            'ms-1': key % 2 != 0,

                            'selected-payment': key == selectedPayment
                        }" class="d-flex align-items-center rounded px-2 py-2">
                            <img :src="value.image" alt="">
                            <label class="form-check-label mx-2" for="inlineRadio2">{{ value.name }}</label>

                        </div>
                    </div>

                </div>

                <label for="payment_method" class="mb-2">ငွေသွင်းရမည့်အကောင့်</label>
                <div class=" border-custom rounded py-2 px-3 mb-4 d-flex align-items-center">
                    <img style="height: 40px;" :src="payment_options[selectedPayment].image" alt="" class="me-2">
                    <div>
                        <span class="fs-14">{{ payment_options[selectedPayment].account_name }}</span>
                        <p class="fs-16 fw-bold mb-0">{{ payment_options[selectedPayment].account_number }}</p>
                    </div>
                    <font-awesome-icon @click="copyNumber(payment_options[selectedPayment].account_number)"
                        class="text-custom ms-auto" :icon="['fas', 'fa-copy']" size="lg"></font-awesome-icon>
                </div>

                <form-group :errors="errors.amount" label="Amount" :required="true" placeholder="Amount ရိုက်ထည့်ပါ"
                    v-model="selectedAmount"></form-group>

                <h2 class="fw-600 fs-14 mb-3">Amount ရွေးပါ</h2>
                <div class="row">
                    <div @click="selectAmount(amount)" v-for="(amount, key) in  amount_options" :key="key"
                        class="col-4 mb-2 text-center">
                        <div :class="{
                            'bg-custom text-white': selectedAmount == amount,
                            'text-custom bg-amount': selectedAmount != amount
                        }" class="text-center py-2 rounded">
                            {{ $helper.formattedNumber(amount) }}
                        </div>
                    </div>
                </div>

                <PrimaryButton @click="next()" class="login-btn text-dark mt-2">ဆက်သွားမည်</PrimaryButton>
            </template>
            <template v-else>
                <div class="text-center">
                    <img style="width:15%;aspect-ratio: 1/1;" :src="payment_options[selectedPayment].image" alt="">
                    <h1 class="fw-600 fs-18 mb-4 mt-4">{{ payment_options[selectedPayment].name }} <span
                            class="fw-400 fs-14">နှင့်ငွေဖြည့်မည်</span>
                    </h1>
                </div>

                <div class="mt-4">
                    <div class="fw-600 fs-14 mb-1">လုပ််ငန်းစဥ်နံပါတ် <span class="fw-400 fs-12">(နောက်ဆုံးဂဏန်း ၆
                            လုံး)</span>
                    </div>
                    <div class="form-group">
                        <form-group required type="text" v-model="transactionId"
                            placeholder="လုပ််ငန်းစဥ်နံပါတ်၏ နောက်ဆုံးဂဏန်း ၆လုံး ထည့်ပါ" :errors="errors.transaction_code">
                        </form-group>

                    </div>

                </div>

                <div class="mt-auto d-flex">
                    <div class="w-50 me-1">
                        <SecondaryButton @click="step = 1" type="submit" class="login-btn mt-auto fw-700 fs-14">ရှေ့သို့
                        </SecondaryButton>

                    </div>
                    <div class="w-50 ms-1">
                        <PrimaryButton type="submit" class="text-dark fw-700 fs-14">တင်ပြမည်
                        </PrimaryButton>
                    </div>
                </div>
            </template>
        </form>
        <hr>
        <div class="border-custom mt-2 rounded">
            <div class="py-2 bg-custom">
                <p class="fw-bold fs-14 mb-0 text-center">ငွေဖြည့်နည်းမသိပါက ဆက်သွယ်ရန်</p>
            </div>

            <div class="p-2 pb-0">
                <div class="row ">
                    <div class="col-12 mb-2" v-for="(types, index) in $store.state.index.services" :key="index">
                        <div class="text-start fs-13" v-if="index == 1">
                            Viber
                        </div>
                        <div class="text-start fs-13" v-if="index == 2">
                            Telegram
                        </div>
                        <div class="text-start fs-13" v-if="index == 3">
                            Phone
                        </div>
                        <div class="row">
                            <div v-for="(phone, i) in types" :key="i" class="col-6 d-flex mb-1">
                                <a v-if="index == 1" :href="'viber://contact?number=' + phone.phone"
                                    class="d-flex align-items-center text-decoration-none">
                                    <font-awesome-icon class="text-custom me-2" icon="fas fa-phone"></font-awesome-icon>
                                    <div class="fw-bold fs-16 text-custom ">
                                        {{ phone.phone }}
                                    </div>
                                </a>
                                <a v-if="index == 2" :href="'https://t.me/number=' + phone.phone"
                                    class="d-flex align-items-center text-decoration-none">
                                    <font-awesome-icon class="text-custom me-2" icon="fas fa-phone"></font-awesome-icon>
                                    <div class="fw-bold fs-16 text-custom ">
                                        {{ phone.phone }}
                                    </div>
                                </a>
                                <a v-if="index == 3" :href="'tel:' + phone.phone"
                                    class="d-flex align-items-center text-decoration-none">
                                    <font-awesome-icon class="text-custom me-2" icon="fas fa-phone"></font-awesome-icon>
                                    <div class="fw-bold fs-16 text-custom ">
                                        {{ phone.phone }}
                                    </div>
                                </a>


                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import SecondaryButton from '@/components/buttons/SecondaryButton.vue';
import userService from '@/services/user.service';
export default {
    data() {
        return {
            step: 1,
            agents: [],
            amount_options: [
                5000, 10000, 20000, 50000, 100000, 2000000
            ],
            showWarningModal: true,
            transactionId: null,
            selectedPayment: 0,
            selectedAmount: null,
            errors: {},
        };
    },
    computed: {
        payment_options() {
            return this.$store.state.index.paymentMethods;
        }
    },
    methods: {
        copyNumber(number) {
            const clipboardData = event.clipboardData ||
                window.clipboardData ||
                event.originalEvent?.clipboardData ||
                navigator.clipboard;
            clipboardData.writeText(number);
            this.$store.dispatch('showToast', 'Copied to clipboard');
        },
        applyTopUp() {
            event.preventDefault();
            this.$store.dispatch('loading');
            userService.applyTopUp({
                payment_method: this.payment_options[this.selectedPayment].id,
                amount: this.selectedAmount,
                transaction_id: this.transactionId,
            }).then((response) => {
                this.$store.dispatch('loadingComplete');
                if (response.status) {
                    this.$router.push({
                        name: "home",
                        params: {
                            type: "topup"
                        }
                    });
                }
            }).catch(({ response }) => {
                console.log(response);
                if (response.status == 422) {
                    this.errors = response.data.errors;
                    if (this.errors.amount) {
                        this.step = 1;
                    }
                }
            });
        },
        selectPayment(index) {
            this.selectedPayment = index;
            console.log(this.selectedPayment);
        },
        selectAmount(amount) {
            this.selectedAmount = amount;
        },
        next() {
            console.log('here');
            if (this.selectedAmount && this.selectedAmount > 0) {
                delete this.errors.amount;
                this.step = 2;
                return;
            }
            this.errors = {
                amount: ['Amount field is required!']
            };
        }
    },
    components: { SecondaryButton }
}
</script>
<style>
.selected-payment {
    background-color: #FFC1071A;
}

.bg-amount {
    background: #FFF0A4 !important;
    color: #000 !important;
}

.amount-input {
    background: #FFF0A4;
    border: 0;
    border-radius: .7rem;
    width: 100%;
    padding: .8rem 1.2rem;
    font-size: .85rem;
}
</style>
