<template>
    <Transition name="slide" appear>

        <div class="toast-box d-flex align-items-center rounded-3 px-3 py-2 text-success" v-if="message">
            <font-awesome-icon :icon="['fas', 'fa-circle-info']" class="me-2"></font-awesome-icon>
            <p class="mb-0 fw-400 fs-14 text-center">{{ message }}</p>
        </div>
    </Transition>
</template>
<script>
export default {
    props: {
        message: {
            type: String
        }
    }
}
</script>
<style scoped>
.toast-box {
    background-color: #fff;

    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    width: 90%;
    max-width: 400px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 3vh;
    margin: auto;
    z-index: 1000;
    color: white;
}

.slide-enter-active {
    transition: all 1s ease-out;
}

.slide-leave-active {
    transition: all 1s ease-in;
}

.slide-enter-from,
.slide-leave-to {
    transform: translateX(50vh);
}
</style>