<template>
    <div class="referr-icon-group">
        <div class="referr-icon referr-icon-1 btn btn-primary rounded-circle">
            <font-awesome-icon icon="fa-solid fa-user-plus" size="xs"></font-awesome-icon>
        </div>

        <div class="referr-icon referr-icon-2 btn btn-light text-custom rounded-circle">
            <font-awesome-icon icon="fa-solid fa-user-plus" size="md"></font-awesome-icon>
        </div>

        <div class="referr-icon referr-icon-3 btn btn-primary rounded-circle">
            <font-awesome-icon icon="fa-solid fa-user-plus" size="lg"></font-awesome-icon>
        </div>
    </div>
</template>
<style>
.referr-icon-group {
    position: relative;
    left: 0;
    width: 100px;
    height: 90px;
}

.referr-icon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

.referr-icon-1 {
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    width: 30px;
    height: 30px;
    transform: rotate(-10deg);
}

.referr-icon-2 {
    position: absolute;
    top: 20px;
    left: 0;
    right: 50%;
    width: 42px;
    height: 42px;
    transform: rotate(-8deg);
}

.referr-icon-3 {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 55px;
    height: 55px;
    transform: rotate(8deg);
}
</style>