<template>
    <div class="d-flex text-center footer-nav bg-custom p-2 py-3" style="z-index: 20;">

        <router-link v-for="(route, index) in routes" :to="{ name: route.routeName }" :key="index"
            class="col-3 footer-nav-item">
            <div>
                <font-awesome-icon :icon="route.icon" :size="route.iconSize"></font-awesome-icon>
                <!-- <p class="footer-label">{{ route.name }}</p> -->
            </div>
        </router-link>

    </div>
</template>
<script>
export default {
    data() {
        return {
            routes: [
                {
                    name: "ပင်မ",
                    icon: "fa-solid fa-house",
                    iconSize: "xl",
                    routeName: "home"
                },
                {
                    name: "ထီပေါက်သူ",
                    icon: "fa-solid fa-trophy",
                    iconSize: "xl",
                    routeName: "2d.winners"
                },
                {
                    name: "အကူအညီ",
                    icon: "fa-solid fa-phone",
                    iconSize: "xl",
                    routeName: "services"
                },
                {
                    name: "ကျွန်ုပ်ပရိုဖိုင်",
                    icon: "fa-solid fa-user-circle",
                    iconSize: "xl",
                    routeName: "profile"
                }
            ]
        }
    }
}
</script>
<style>
.footer-nav {
    border-top: 1px solid #00000010;
    position: fixed;
    bottom: 0;
    height: 55px;
    width: 100vw;
    max-width: 450px;
    left: 0;
    right: 0;
    margin: auto;
}

.footer-nav-item {
    color: #000000B2;
    text-decoration: none;
}

.footer-nav-item.router-link-active {
    color: #ff0000 !important;
}

.footer-label {
    margin-top: 3px;
    font-size: 12px;
    margin-bottom: 0;
}
</style>