<template>
    <div class="p-4">
        <form @submit="goToTopUP()" class="d-flex flex-column h-100">
            <div class="options row p-0">
                <div @click="selectPayment(value.type)" class="col-6" :class="{
                    'ps-0': (key + 1 % 2 != 0),
                    'pe-0': (key + 1 % 2 == 0),
                }" v-for="(value, key) in payment_options" :key="key">
                    <div :class="{
                        'border-primary': selectedPayment == value.type,
                        'selected-payment': selectedPayment == value.type
                    }" class="d-flex align-items-center border rounded px-2 py-2">
                        <img :src="require('@/assets/payments/' + value.icon)" alt="">
                        <label class="form-check-label mx-2" for="inlineRadio2">{{ value.name }}</label>
                        <font-awesome-icon v-if="selectedPayment == value.type" icon="fa-regular fa-circle-dot"
                            class="text-custom ms-auto" size="lg" />
                        <font-awesome-icon v-else icon="fa-regular fa-circle" class="text-custom ms-auto" size="lg" />
                    </div>
                </div>

            </div>
            <div class="mt-4">
                <h1 class="fw-600 fs-18 mb-4">{{ payment_options.filter((option) => option.type == selectedPayment).name }}
                    <span class="fw-400 fs-14">နှင််ငွေဖြည့်မည်</span>
                </h1>
                <h2 class="fw-600 fs-14 mb-3">Select amount</h2>
                <div class="row">
                    <div @click="selectAmount(amount)" v-for="(amount, key) in  amount_options" :key="key"
                        class="col-4 mb-2 text-center">
                        <div :class="{
                            'bg-custom text-white': selectedAmount == amount,
                            'text-custom bg-amount': selectedAmount != amount
                        }" class="text-center py-2 rounded">
                            {{ $helper.formattedNumber(amount) }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex align-items-center my-2">
                <span style="flex-grow:1;height: 1px;background-color: #EBF0FD;"></span>
                <span class="fw-400 fs-14 mx-2">or</span>
                <span style="flex-grow:1;height: 1px;background-color: #EBF0FD;"></span>
            </div>

            <form-group label="Amount" :required="true" placeholder="Enter amount" v-model="selectedAmount"></form-group>

            <div class="mt-3 d-flex align-items-center">
                <font-awesome-icon icon="fa-brands fa-youtube" class="me-2 text-custom" size="lg" />
                <span class="fw-400 fs-13 text-decoration-underline">အမှားအယွင်းမရှိစေရန် ငွေဖြည့်နည်း ကြည့်ပါ</span>
            </div>

            <PrimaryButton type="submit" class="login-btn mt-auto">ဆက်သွားမည်</PrimaryButton>
        </form>
    </div>
</template>
<script>
export default {
    data() {
        return {
            selectedPayment: null,
            selectedAmount: null,
            showWarningModal: false,
            payment_options: [
                {
                    name: "KBZ Pay",
                    icon: "kpay.svg",
                    type: "kpay",
                    id: 1,
                },
                {
                    name: "Wave Pay",
                    icon: "wave.svg",
                    type: "wavepay",
                    id: 2
                }
            ],
            amount_options: [
                5000, 10000, 50000, 100000, 2000000
            ]
        };
    },
    mounted() {
        this.selectedPayment = this.payment_options[0].type
        this.showWarningModal = true
    },
    methods: {
        goToTopUP() {
            this.$router.push({
                name: "top-up",
                params: {
                    amount: this.selectedAmount,
                    type: this.selectedPayment
                }
            });
        },
        selectPayment(index) {
            this.selectedPayment = index;
        },
        selectAmount(amount) {
            this.selectedAmount = amount;
        }
    },
}
</script>
<style>
.selected-payment {
    background-color: #FFC1071A;
}

.bg-amount {
    background: #FFF0A4;
}

.amount-input {
    background: #FFF0A4;
    border: 0;
    border-radius: .7rem;
    width: 100%;
    padding: .8rem 1.2rem;
    font-size: .85rem;
}
</style>
