<template>
    <div class="three-d-section">
        <div style="position: fixed;width:40%;max-width:250px;top: 0;text-align: center;height: 50px;left: 0;right: 0;margin: auto;"
            class="d-flex align-items-center mx-auto justify-content-center">
            <h1 class="fw-600 mb-0 fs-16 text-dark">{{
                selectedSection.date }} <span class="fw-400 fs-12">({{
        selectedSection.multiply }}ဆ)</span> </h1>
        </div>

        <div class="content  p-3">
            <h1 class="fw-600 mb-4 fs-14 text-center">ထိုးမည့်ဂဏန်းများ</h1>


            <div class="d-flex flex-column bg-white pb-0 w-100 h-100">
                <div class="">
                    <div class="row mb-2">
                        <div class="col-3 text-center">
                            <span class="fw-600 fs-12">နံပါတ်</span>
                        </div>
                        <div class="col-3 text-center">
                            <span class="fw-600 fs-12">ဆ</span>
                        </div>
                        <div class="col-4 text-center">
                            <span class="fw-600 fs-12">ကြေး</span>
                        </div>
                    </div>
                    <div class="horizontal-line mb-2"></div>

                    <div class="row align-items-center pb-3" v-for="(number, index) in selectedNumbers" :key="index">
                        <div class="col-3 text-center">
                            <form-group pattern="/^-?\d+\.?\d*$/" @keypress="handleMaxDigit"
                                class="fw-400 fs-14 text-center" maxlength="3" type="tel"
                                v-model="selectedNumbers[index].number" size="sm"></form-group>
                        </div>
                        <div class="col-3">
                            <form-group class="fw-400 fs-14" :readOnly="true" :modelValue="selectedSection.multiply"
                                size="sm"></form-group>
                        </div>
                        <div class="col-4">
                            <form-group class="fw-400 fs-14 text-center" :hasError="errors[number.number]" type="tel" v-model="number.amount"
                                size="sm"></form-group>
                        </div>
                        <div class="col-2 d-flex align-items-center">
                            <span @click="removeNumber(number)" style="width:30px;aspect-ratio: 1/1;"
                                class="d-flex align-items-center justify-content-center bg-danger text-white rounded-circle me-2">
                                <font-awesome-icon icon="fa-solid fa-trash" size="xs" />
                            </span>
                        </div>
                         <div class="col-10 text-end ms-auto" style="line-height: 1;">
                            <small class="text-danger fs-12 fw-400">{{errors[number.number]}}</small>
                        </div>
                        <div class="col-2"></div>
                    </div>
                </div>


            </div>
        </div>
        <div class="bet-amount-box py-3 px-3 bg-custom2">
            <div class="d-flex align-items-center mb-2">
                <div class="d-flex align-items-center">
                    <span class="fw-400 fs-12 mx-2">လက်ကျန်ငွေ</span>
                    <span class="fw-600 fs-16 text-dark">{{ $helper.formattedNumber(currentUser.balance)}} Ks</span>
                </div>


                <div class="ms-auto">
                    <button @click="showQuickChooseModal = true"
                        class="btn font-poppins btn-outline-custom outline-btn text-custom w-100 py-2"><!-- <font-awesome-icon icon="fa-solid fa-retweet" size="sm" /> --><span
                            class="fw-600 fs-14 ms-2">အမြန်ရွေးမည်</span></button>
                </div>
            </div>
            <form @submit.prevent="addNumber" class="d-flex align-items-center pb-2">
                <div class="col-3 pe-1">
                    <input v-model="number" @keypress="handleMaxDigit" placeholder="ဂဏန်း"
                        class="border text-center border-1 border-dark rounded px-2 py-2 w-100" type="tel">
                </div>

                <div class="col-4 px-1">
                    <input v-model="amount" placeholder="ငွေ" class="border text-center border-1 border-dark rounded  px-2 py-2 w-100"
                        type="tel">
                </div>
                <div class="col-2 px-1">
                    <PrimaryButton type="submit" @click="rMode = true" style="height: 100%;" class="text-dark">Rမည်
                    </PrimaryButton>

                </div>
                <div class="col-3">
                    <PrimaryButton type="submit" style="height: 100%;" class="text-dark">ထည့်မည်</PrimaryButton>

                </div>


            </form>
            <div class="d-flex mt-2 align-items-center">
                <div class="col-7">
                    <div class="rounded-3 d-flex justify-content-center align-items-center">
                        <span class="fw-400 text-dark fs-12 text-center">စုစုပေါင်းထိုးငွေ : </span>
                        <span class="fw-bold fs-14">{{ this.$helper.formattedNumber(totalBetAmount) }} Ks</span>
                    </div>


                </div>
                <div class="col-5 ps-2">
                    <PrimaryButton @click="bet()" class="text-dark">ထိုးမည်</PrimaryButton>
                </div>
            </div>
        </div>

        <ModalBox :show="showQuickChooseModal" @close="showQuickChooseModal = false;">
            <template v-slot:header>အမြန်ရွေးရန််</template>
            <template v-slot:body>
                <div class="mt-1">
                    <div class="row">
                        <div class="col-6">
                            <PrimaryButton @click="t">ထွိုင်</PrimaryButton>
                        </div>
                        <div class="col-6">
                            <PrimaryButton @click="showSeriesModal = true; showQuickChooseModal = false">စီးရီး
                            </PrimaryButton>

                        </div>
                    </div>
                </div>
            </template>
        </ModalBox>


        <ModalBox :show="showSeriesModal" @close="showSeriesModal = false">
            <template v-slot:header>စီးရီး</template>
            <template v-slot:body>

                <div>
                    <div class="row">
                        <div class="btn-group mb-3" role="group" aria-label="Basic radio toggle button group">
                            <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" :checked="seriesMode == 1">
                            <label class="btn btn-outline-warning text-dark" for="btnradio1" @click="seriesMode = 1">နောက်စီးရီး</label>

                            <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" :checked="seriesMode == 2">
                            <label class="btn btn-outline-warning text-dark" for="btnradio2" @click="seriesMode = 2">အလယ်စီးရီး</label>

                            <input type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off" :checked="seriesMode == 3">
                            <label class="btn btn-outline-warning text-dark" for="btnradio3" @click="seriesMode = 3">ရှေ့စီးရီး</label>
                        </div>
                        <FormGroup v-model="seriesNumber" type="tel" class="col-6" label="စီးရီးထည့်ရန်ဂဏန်းထည့်ပါ"></FormGroup>
                        
                        <FormGroup v-model="seriesAmount" type="tel" class="col-6" label="amount ထည့်ပါ" placeholder="100"></FormGroup>
                    </div>
                    <PrimaryButton @click="series">ထည့်မည်</PrimaryButton>
                </div>

            </template>
        </ModalBox>

    </div>
</template>
<script>
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import ModalBox from '@/components/ModalComponent.vue';
import userService from '@/services/user.service';
import moment from 'moment';
export default {
    components: {
        ModalBox,
        PrimaryButton,
    },
    data() {
        return {
            showQuickChooseModal: false,
            selectedSection: {},
            showSeriesModal: false,
            seriesMode:1,
            seriesNumber: null,
            seriesAmount: null,
            errors:{},
            showModalBox: false,
            searching: false,
            searchInput: null,
            showColorDescriptionModal: false,
            numbers: [
            ],
            selectedNumbers: [],
            logined: this.$store.state.auth.status.loggedIn,
            currentUser: this.$store.state.auth.user,
            countDown: null,
            amount: 100,
            number: null,
            rMode: false,
        }
    },
    mounted() {
        let selectedSection = this.$route.params.section
        if (selectedSection) {
            userService.get3DScheduleDetail(selectedSection).then(({ data }) => {
                this.numbers = data.data.numbers
                this.selectedSection = data.data
                this.amount = this.selectedSection.minimum_amount
                this.seriesAmount = this.selectedSection.minimum_amount
            })

        } else {
            this.$router.push({ name: 'home' })
        }
    },
    computed: {
        totalBetAmount() {
            return this.selectedNumbers.reduce((total, value) => {
                return total + parseInt(value.amount);
            }, 0);
        },

        displayNumbers() {
            if (this.searchInput) {
                return this.numbers.filter((number) => {
                    return number.number.includes(this.searchInput)
                })
            }
            return this.numbers;
        },
    },
    methods: {
        handleMaxDigit(event) {
            const inputText = event.target.value;
            if (/\d{3,}/.test(inputText)) {
                event.preventDefault()
                return false
            }
            return true;
        },
        initiateSearch() {
            this.searching = true
        },
        startCountDown() {
            var that = this;
            let coundDownInterval = setInterval(function () {
                var eventTime = moment(new Date(moment().format("YYYY-MM-DD") + ' ' + that.selectedSection.end_time)); // Timestamp - Sun, 21 Apr 2013 13:00:00 GMT
                var currentTime = moment(new Date());
                var diffTime = eventTime - currentTime;
                if (diffTime < 1) {
                    that.countDown = "0 : 0 : 0";
                    clearInterval(coundDownInterval)
                } else {
                    var duration = moment.duration(diffTime, 'milliseconds');
                    that.countDown = (duration.hours() + " : " + duration.minutes() + " : " + duration.seconds())
                }
            }, 1000);
        },
        t() {
            if (parseInt(this.amount) < this.selectedSection.minimum_amount) {
                this.$store.dispatch('showError', 'တစ်ကွက်လျှင်အနည်းဆုံး' + this.selectedSection.minimum_amount + 'Ks ထိုးပေးပါ!');
                return;
            }
            let numbers = this.$3d.getNumbers("T", this.amount)
            numbers.forEach(n => {
                this.selectedNumbers.push({
                    number: n.number,
                    amount: n.amount
                })
            })
            this.showQuickChooseModal = false
        },
        series() {
            if (!this.seriesNumber || this.seriesNumber.toString().match(/[^$,.\d]/) || this.seriesNumber.toString().length != 2) {
                this.$store.dispatch('showError', 'ဂဏန်း 2 လုံး ထည့်ပါ။')
            }
            else if (!this.seriesAmount || this.seriesAmount.toString().match(/[^$,.\d]/) || parseInt(this.seriesAmount) < this.selectedSection.minimum_amount){
                    this.$store.dispatch('showError', 'တစ်ကွက်လျှင်အနည်းဆုံး ' + this.selectedSection.minimum_amount + 'Ks ထိုးပေးပါ!');
                
            } else {
                let numbers = [];
                if (this.seriesMode == 3) {
                    numbers = this.$3d.getNumbers('*' + this.seriesNumber, this.seriesAmount)   
                }else if(this.seriesMode == 2){
                    numbers = this.$3d.getNumbers(this.seriesNumber[0] + '*' + this.seriesNumber[1], this.seriesAmount)
                }else{
                    numbers = this.$3d.getNumbers(this.seriesNumber + '*', this.seriesAmount)
                }
                numbers.forEach(n => {
                    this.selectedNumbers.push({
                        number: n.number,
                        amount: n.amount
                    })
                })
                this.showSeriesModal = false
                this.showQuickChooseModal = false
                this.seriesNumber = null
            }
        },
        addNumber() {
            this.number = this.number.toString().replace(/\D/g, '')
            this.amount = this.amount.toString().replace(/\D/g, '')
            if (this.number.toString().length != 3) {
                this.$store.dispatch('showError', 'Invalid Number!');
                return;
            }

            if ((this.number))

                if (parseInt(this.amount) < this.selectedSection.minimum_amount) {
                    this.$store.dispatch('showError', 'တစ်ကွက်လျှင်အနည်းဆုံး' + this.selectedSection.minimum_amount + 'Ks ထိုးပေးပါ!');
                    return;
                }

            if (this.rMode) {
                let numbers = this.$3d.getNumbers(this.number + "R", this.amount)
                numbers.forEach(n => {
                    this.selectedNumbers.push({
                        number: n.number,
                        amount: parseInt(n.amount)
                    })
                })
            } else {
                this.selectedNumbers.push({
                    number: this.number,
                    amount: parseInt(this.amount)
                })
            }


            this.number = null
            this.rMode = false
        },
        toggleNumber(number) {
            if (number.percent > 99.99) {
                return;
            }

            let index = this.selectedNumbers.findIndex(n => {
                return n.id == number.id
            });

            if (index != -1) {
                this.selectedNumbers.splice(index, 1)
            } else {
                this.selectedNumbers.push({
                    id: number.id,
                    number: number.number,
                    amount: this.amount
                })
            }
        },
        removeNumber(number) {
            let index = this.selectedNumbers.findIndex(n => {
                return n.id == number.id
            });
            if (index != -1) {
                this.selectedNumbers.splice(index, 1)
            }
        },
        round() {
            this.selectedNumbers.forEach(selectedNumber => {
                let number = selectedNumber.number;
                let round_number = this.numbers.filter(n => {
                    n = n.number
                    return (n.indexOf(number[0]) > -1) && (n.indexOf(number[1]) > -1) && (n.indexOf(number[2]) > -1);
                })

                round_number.forEach(value => {
                    let round_number_index = this.selectedNumbers.findIndex(n => {
                        return n.number == value.number
                    });
                    if (round_number_index == -1) {
                        this.selectedNumbers.push({
                            id: value.id,
                            number: value.number,
                            amount: selectedNumber.amount
                        })
                    }
                })


            })
        },
        startBetting() {
            this.selectedNumbers.forEach((number, index) => {
                this.selectedNumbers[index].amount = this.amount
            })
            this.showModalBox = true
        },
        bet() {


            if (this.selectedNumbers.length <= 0) {
                this.$store.dispatch('showError', 'Invalid Number!');
                throw "Invalid Number";
            }

            this.$store.dispatch('loading')
            this.selectedNumbers.forEach(n => {
                if (parseInt(n.amount) < this.selectedSection.minimum_amount) {
                    this.$store.dispatch('loadingComplete')
                    this.$store.dispatch('showError', 'တစ်ကွက်လျှင်အနည်းဆုံး' + this.selectedSection.minimum_amount + 'Ks ထိုးပေးပါ!');
                    throw "Invalid Number";
                }


                if (n.number.toString().match(/[^$,.\d]/) || n.number.toString().match(/[^$,.\d]/) || n.number.toString().length != 3) {
                    this.$store.dispatch('loadingComplete')
                    this.$store.dispatch('showError', 'Invalid Number!');
                    throw "Invalid Number";
                }
            })


            userService.bet3DSchedule(this.selectedSection.id, {
                bet_data: this.selectedNumbers
            }).then(({ data }) => {
                this.$store.dispatch('loadingComplete')
                if (data.success) {
                    this.$store.dispatch('showToast', data.message)
                    this.$router.push({ name: 'home' })
                }
            }).catch(({ response }) => {
                if (response.status == 403) {
                   if (response.data.message) {
                        this.$store.dispatch('showError', response.data.message)
                    } 

                    if (response.data.errors) {
                        this.errors = response.data.errors
                    }
                }
            })
        }
    },
}

</script>
<style>
/* .three-d-section .banner {
    margin-top: -1px;
    color: #fff;
    background-color: #FFF0A4;
    height: 9vh;
} */

.three-d-section .content {
    margin-bottom: 185px;
    overflow: auto;
}

.three-d-section .number {
    width: 20%;
}

.number .digit {
    border: 2px solid #FFC107 !important;

    color: #000;
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.number .icon {
    bottom: 5px;
    font-size: .4rem;
}

.number .full {
    background-color: transparent !important;
    color: #9e9e9e !important;
    border: 1px solid #FFF0A4;

}

.number .choosed {
    background-color: #FFC107 !important;
    color: #000 !important;
}

.three-d-section .bet-amount-box {
    position: fixed;
    width: 100%;
    max-width: 450px;
    bottom: 0;
    border-top: 1px solid #FFF0A4;
    height: 185px;
    z-index: 1000;
}

.bet-amount-box input {
    border: none;
    outline: none;
    background-color: transparent;
}

.search-box input {
    border: none;
    outline: none;
    background-color: transparent;
}

.rCheck {
    -webkit-appearance: none;
    width: 30px;
    height: 30px;
    background: white;
    border-radius: 5px;
    border: 2px solid #555;
}

.rCheck:checked {
    background: #abd;
}
</style>