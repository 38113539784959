<template>
    <div class="apply-top-up" style="width: 100vw;">
        <div class="banner">
            <!-- <img src="@/assets/check.svg" alt=""> -->
        </div>
        <div class="description text-center text-white px-4 py-2">
            <h1 class="fw-700 fs-18">{{ types[$route.params.type].title }}</h1>
            <p class="fw-400 fs-14 my-4">{{ types[$route.params.type].description }}</p>
            <router-link :to="{
                name: 'home'
            }">
                <OrdinaryButton class="fw-600 fs-14">Okay</OrdinaryButton>
            </router-link>
            <div class="fw-400 fs-14 mt-4 text-decoration-underline" @click="showModal = true">အကူအညီ</div>
        </div>
        <modal-box @close="showModal = false" :show="showModal">


            <template v-slot:header>အကူအညီ</template>

            <template v-slot:body>
                <ul class="list-group list-group-flush">
                    <li v-for="(help, index) in helps" :key="index" class="list-group-item d-flex align-items-center">
                        <font-awesome-icon :icon="help.icon" size="lg" class="me-3 text-custom" />
                        <div>
                            <p class="fw-400 fs-12 mb-1 text-capitalize">{{ help.type }}</p>
                            <h1 class="fw-600 fs-14 mb-0">{{ help.phone }}</h1>
                        </div>
                        <span class="fw-500 fs-13 ms-auto text-custom">{{
                            help.type == 'call' ? 'ဖုန်းခေါ်မည်' :
                            'စကားပြောမည်'
                        }}</span>
                    </li>
                </ul>
            </template>
        </modal-box>
    </div>
</template>
<script>
import ModalBox from '@/components/ModalComponent.vue';
import userService from '@/services/user.service';
export default {
    components: {
        ModalBox
    },
    data() {
        return {
            showModal: false,
            types: {
                withdraw: {
                    title: "ငွေထုတ်ရန် ပြုလုပ်နေပါသည်",
                    description: "ငွေထုတ်ရန်ပြုလုပ်နေပါသည်။ 5 မိနစ်အတွင်း ငွေရောက်ပါမည်။"
                },
                topup: {
                    title: "ငွေဖြည့်ရန် တင်ပြနေပါသည်",
                    description: "သတ်မှတ်ချိန်အတွင်းငွေဖြည့်ပါက 5 မိနစ်အတွင်း ငွေရောက်ပါမည်"
                }
            },
            helps: [
                {
                    type: "call",
                    icon: "fa-solid fa-phone",
                    name: "Cellular Call",
                    phone: "09979973858"
                },
                {
                    type: "call",
                    icon: "fa-brands fa-viber",
                    name: "Viber Call",
                    phone: "09979973858"
                },
                {
                    type: "chat",
                    icon: "fa-brands fa-viber",
                    name: "Viber Chat",
                    phone: "09979973858"
                }
            ]
        }
    },
    mounted() {
        userService.getAgentsInfo().then(response => {
            this.helps = response.data.data
        })
    }
}
</script>
<style scoped>
.apply-top-up .banner {
    height: 60vh;
    width: 100vw;
    position: relative;
    background-position: center;
    object-fit: cover;
    background-size: 100vw 20vh;
    /* background: url('../assets/home-banner.png'); */
}

.banner img {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 30vw;
}

.apply-top-up .description {
    height: 45vh;
}

.apply-top-up {
    background-color: #0B1C30;
}
</style>