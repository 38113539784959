<template>
    <div class="p-4" style="height: calc(100vh - 30px);overflow-y: auto;">
        <div v-for="(transactions, index) in history" :key="index" class="mb-4">
            <p class="fw-400 mb-0 fs-14">{{ index }}</p>
            <ul class="list-group list-group-flush">

                <li class="list-group-item px-0" v-for=" (transaction, index) in transactions" :key="index">
                    <router-link :to="{ name: 'transaction-detail', params: { transaction_id: transaction.id } }"
                        class="py-2 text-decoration-none d-flex align-items-center text-dark">

                        <font-awesome-icon class="text-custom me-2" v-if="transaction.type == '+'"
                            :icon="['fas', 'fa-circle-dollar-to-slot']"></font-awesome-icon>
                        <font-awesome-icon class="text-custom me-2" v-if="transaction.type == '-'"
                            :icon="['fas', 'fa-coins']"></font-awesome-icon>


                        <div class="ms-2">
                            <h1 class="fw-700 fs-14 mb-1">
                                {{
                                    transaction.transaction_type
                                }}
                            </h1>
                            <span class="fw-400 fs-12">{{ transaction.date_time }}</span>

                        </div>
                        <div class="ms-auto">
                            <h1 :class="{ 'text-success': transaction.status == 2, 'text-warning': transaction.status == 1, 'text-danger': transaction.status == 3 }"
                                class="fw-600 fs-14 mb-1">{{
                                    transaction.type }} <span> {{
        $helper.formattedNumber(transaction.amount)
    }} </span>
                                Ks</h1>

                        </div>

                    </router-link>
                </li>

            </ul>
            <div class="transaction-line"></div>
        </div>
    </div>
</template>
<script>
import userService from '@/services/user.service';

export default {
    data() {
        return {
            history: [
                // {
                //     month: "ဇန်န၀ါရီ",
                //     history: [
                //         {
                //             type: "-",
                //             to: "*******456",
                //             amount: "50000",
                //             date_time: "Today 16:56:18"
                //         },
                //         {
                //             type: "+",
                //             to: "*******456",
                //             amount: "40000",
                //             date_time: "Today 16:56:18"
                //         }
                //     ]
                // },
                // {
                //     month: "ဒီဇင်ဘာ",
                //     history: [
                //         {
                //             type: "-",
                //             to: "*******456",
                //             amount: "50000",
                //             date_time: "Today 16:56:18"
                //         },
                //         {
                //             type: "+",
                //             to: "*******456",
                //             amount: "40000",
                //             date_time: "Today 16:56:18"
                //         }
                //     ]
                // }
            ]
        }
    }
    ,
    mounted() {
        this.fetchHistory()
    },
    methods: {
        fetchHistory() {
            this.$store.dispatch('loading')
            userService.getTransactionHistory().then(({ data }) => {
                this.$store.dispatch('loadingComplete')
                this.history = data.data
            })
        }
    }
}
</script>
<style>
.transaction-line {
    border: 1px solid #EBF0FD;
}
</style>