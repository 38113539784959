<template>
    <div class="py-3">
        <div class="px-3">
            <!-- <div class="d-flex mb-2">
                <div class="col-6 pe-1">
                    <PrimaryButton>2D ထီပေါက်သူ</PrimaryButton>
                </div>
                <div class="col-6 ps-1">
                    <router-link :to="{ name: '3d.winners' }">
                        <SecondaryButton>3D ထီပေါက်သူ</SecondaryButton>
                    </router-link>
                </div>
            </div> -->
            <div class="d-flex">
                <div class="col-4 pe-1" v-if="second_winner" style="position: relative;z-index: 999;bottom: -23px;">
                    <div class="text-center p-2 border rounded bg-white">
                        <img :src="second_winner.avatar" style="width: 40px;" alt="" class="rounded-circle">
                        <div class="">
                            <div class="mb-0 fs-12 fw-500" style="line-height: 1;">{{ second_winner.name }}</div>
                            <div class="fw-bold fs-12 mb-2 ">{{ second_winner.phone }}</div>
                            <div class="d-flex justify-content-between">
                                <div class="fs-11 fw-500 text-end ">ထိုးငွေ</div>
                                <div class="fs-12 fw-500 text-end ">{{ $helper.formattedNumber(second_winner.amount) }}
                                </div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <div class="fs-11 fw-500 text-end ">အနိုင်ရငွေ</div>
                                <div class="fs-12 fw-bold text-end ">{{ $helper.formattedNumber(second_winner.win_amount) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4" v-if="first_winner">
                    <div class="text-center p-2 border rounded bg-white">
                        <img :src="first_winner.avatar" style="width: 40px;" alt="" class="rounded-circle">
                        <div class="">
                            <div class="mb-0 fs-12 fw-500" style="line-height: 1;">{{ first_winner.name }}</div>
                            <div class="fw-bold fs-12 mb-2 ">{{ first_winner.phone }}</div>
                            <div class="d-flex justify-content-between">
                                <div class="fs-11 fw-500 text-end ">ထိုးငွေ</div>
                                <div class="fs-12 fw-500 text-end ">{{ $helper.formattedNumber(first_winner.amount) }}</div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <div class="fs-11 fw-500 text-end ">အနိုင်ရငွေ</div>
                                <div class="fs-12 fw-bold text-end ">{{ $helper.formattedNumber(first_winner.win_amount) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4 ps-1" v-if="third_winner">
                    <div class="text-center p-2 border rounded bg-white"
                        style="position: relative;z-index: 999;bottom: -33px;">
                        <img :src="third_winner.avatar" style="width: 40px;" alt="" class="rounded-circle">
                        <div class="">
                            <div class="mb-0 fs-12 fw-500" style="line-height: 1;">{{ third_winner.name }}</div>
                            <div class="fw-bold fs-12 mb-2 ">{{ third_winner.phone }}</div>
                            <div class="d-flex justify-content-between">
                                <div class="fs-11 fw-500 text-end ">ထိုးငွေ</div>
                                <div class="fs-12 fw-500 text-end ">{{ $helper.formattedNumber(third_winner.amount) }}</div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <div class="fs-11 fw-500 text-end ">အနိုင်ရငွေ</div>
                                <div class="fs-12 fw-bold text-end ">{{ $helper.formattedNumber(third_winner.win_amount) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="px-4">
                <img src="../assets/winner-stage.png" style="width: 100%;height: 80px;" alt="">

            </div>
        </div>
        <hr>
        <div class="winners p-2 mt-2">
            <div class="d-flex mb-3 align-items-center">
                <div class="col-6">
                    <div class="fs-14 fw-500 text-start ">ထိုးသား</div>
                </div>
                <div class="col-3 px-1">
                    <div class="fs-14 fw-500 text-end ">ထိုးငွေ</div>
                </div>
                <div class="col-3 px-1">
                    <div class="fs-14 fw-500 text-end ">အနိုင်ရငွေ</div>
                </div>
            </div>
            <ul class="list-group list-group-flush">
                <li class="list-group-item d-flex align-items-center" v-for="(winner, index) in other_winners" :key="index">
                    <div class="col-6 d-flex align-items-center">
                        <span class="number ">{{ index + 1 }}</span>
                        <img class="me-2 ms-2 rounded-circle" style="width: 40px !important;" :src="winner.avatar">
                        <div>
                            <h1 class="mb-0 label">{{ winner.name }}</h1>
                            <span class="bet-amount-label mb-0 me-2">{{ winner.phone }}</span>
                        </div>
                    </div>
                    <div class="col-3 text-end">
                        <span class="fs-14 fw-500 ">{{ $helper.formattedNumber(winner.amount) }}</span>
                    </div>
                    <div class="col-3 text-end">
                        <span class="fs-14 fw-600 ">{{ $helper.formattedNumber(winner.win_amount) }}</span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import userService from '@/services/user.service';

export default {
    data() {
        return {
            first_winner: {},
            second_winner: {},
            third_winner: {},
            other_winners: [],
        };
    },
    mounted() {
        userService.get2DWinnerList().then(({ data }) => {
            let winners = data.winners;
            this.first_winner = winners[0];
            this.second_winner = winners[1];
            this.third_winner = winners[2];
            this.other_winners = winners.splice(3);
            console.log(this.other_winners);
        });
    },
}
</script>
<style scoped>
.winners-nav {
    background-color: #FFC107;
    text-align: center;
    color: white;
    outline: none;
    border: none;
}

.winner-nav-item {
    padding: 10px 0px;
}

.winner-nav-item.active {
    background-color: #FFC107;
}

.winner {
    display: flex;
    flex-direction: column;
}


.winner-rank span {
    background-color: #ffffff;
    width: 20px;
    height: 20px;
    color: #FFC107;
    border-radius: 50%;
    bottom: -8px;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    font-weight: 600;
    font-size: 14px;
}

.first-winner {
    justify-content: start;
    margin: 0px 20px;
}

.second-winner {
    justify-content: end;
}

.third-winner {
    justify-content: end;
}

.winner-banner {
    background: url('../assets/winner-stage.png');
    height: 40vh;
    position: relative;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    border-radius: 0px 0px 1rem 1rem;
}

.winner-name {
    color: white;
    font-weight: 600;
    font-size: 12px;
}

.winner-phone {
    color: #CEDFFF;
    font-weight: 400;
    font-size: 12px;
}

.winner-amount .title,
.winners .title {
    font-weight: 700;
    font-size: 16px;
}

.winner-amount .comment {
    font-weight: 500;
    font-size: 14px;
    text-decoration: none;
}

.winner-amount .label,
.winners .label {
    font-weight: 600;
    font-size: 14px;
}

.winner-amount .bet-amount-label,
.winners .bet-amount-label {
    font-weight: 400;
    font-size: 11px;
}

.winner-amount .bet-amount,
.winners .bet-amount {
    font-weight: 600;
    font-size: 12px;
}

.winner-image img {
    width: 15vw;
    height: 15vw;
}

.winners .number {
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
}
</style>