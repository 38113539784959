<template>
    <div class="p-3">
        <div v-if="mode == 'bet_list'" class="row mb-4">
            <div class="col-6 pe-1">
                <PrimaryButton class="text-dark">ထိုးမှတ်တမ်း</PrimaryButton>
            </div>
            <div class="col-6 ps-1">
                <SecondaryButton @click="mode = 'number_list'">ထီပေါက်စဉ်မှတ်တမ်း</SecondaryButton>

            </div>
        </div>
        <div v-else class="row mb-4">
            <div class="col-6 pe-1">
                <SecondaryButton @click="mode = 'bet_list'">ထိုးမှတ်တမ်း</SecondaryButton>
            </div>
            <div class="col-6 ps-1">
                <PrimaryButton class="text-dark">ထီပေါက်စဉ်မှတ်တမ်း</PrimaryButton>

            </div>
        </div>
        <template v-if="mode == 'bet_list'">
            <div class="d-flex">
                <div class="w-50 text-start fw-600 fs-12">ရက်စွဲ</div>
                <div class="w-50 text-end fw-600 fs-12 me-4">စုစုပေါင်းထိုးငွေ</div>
            </div>
            <ul class="list-group list-group-flush">
                <router-link :to="{
                    name: '3d-betting-list-detail', params: {
                        date: item.date
                    }
                }" v-for="(item, index) in bet_lists" :key="index"
                    class="list-group-item py-3 d-flex align-items-center">
                    <h1 class="fw-600 fs-16 mb-0">{{ item.date }}</h1>
                    <p class="ms-auto me-4 fw-400 fs-16 mb-0">{{ $helper.formattedNumber(item.total_amount) }} Ks</p>
                    <font-awesome-icon icon="fa-solid fa-chevron-right" />
                </router-link>
            </ul>
        </template>
        <template v-else>

            <ul class="list-group list-group-flush">
                <div v-for="(history, index) in histories" :key="index" class="list-group-item py-3"
                    :class="{ 'pt-0': index == 0 }">
                    <div class="d-flex mb-2">
                        <div class="w-50 text-start fw-500 fs-12">ရက်စွဲ</div>
                        <div class="w-50 text-end fw-500 fs-12">ဂဏန်း</div>
                    </div>
                    <div class="d-flex align-items-center">
                        <h1 class="fw-600 fs-16 mb-0">{{ history.date }}</h1>
                        <p class="ms-auto fw-600 text-danger fs-16 mb-0">{{ history.number }}</p>
                    </div>

                </div>
            </ul>
        </template>
    </div>
</template>
<script>
import userService from '@/services/user.service';

export default {
    data() {
        return {
            mode: 'bet_list',
            histories: [],
            bet_lists: []
        }
    },
    mounted() {
        this.$store.dispatch('loading')
        userService.getUser3DBettingHistory().then((response) => {
            this.bet_lists = response.data.data
            this.histories = response.data.histories
            this.$store.dispatch('loadingComplete')
        })
    }
}
</script>
<style>
.winners-nav {
    background-color: #FFC107;
    text-align: center;
    color: white;
    outline: none;
    border: none;
}

.winner-nav-item {
    padding: 10px 0px;
    color: #fff;
    text-decoration: none;
}

.winner-nav-item.active {
    background-color: #FFC107;
}
</style>