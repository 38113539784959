<template>
    <button :type="type" class="btn font-poppins btn-light fw-600 rounded-3 fs-14 secondary-btn w-100">
        <slot></slot>
    </button>
</template>
<script>
export default {
    props: {
        type: {
            type: String,
            default: "button"
        }
    }
}
</script>
<style>
.secondary-btn {
    padding: .6rem 0 !important;
    background-color: #FFF0A4 !important;
    border-color: #FFF0A4 !important;
    outline-color: #FFF0A4 !important;
    border: 2px solid #FFC107 !important;
    color: #000;
}
</style>