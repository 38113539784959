import userService from "@/services/user.service";

export const index = {
  state: {
    loading: 0,
    errorMessage: null,
    toastMessage: null,
    homeBannerText: null,
    homeBannerImages: [],
    paymentMethods: [],
    services: [],
    serviceBannerText:""
  },
  actions: {
    loading({ commit }) {
        commit('increaseLoading');
    },
    loadingComplete({ commit }) {
        commit('decreaseLoading');
    },
    showError({ commit }, message) {
      commit('showError',message)
    },
    hideError({ commit }) {
      commit('hideError')
    },
    showToast({ commit }, message) {
      commit('showToast',message)
    },
    hideToast({ commit }) {
      commit('hideToast')
    },
    getHomeBanner({ commit }) {
      userService.getHomeBanner().then((response) => {
        commit('updateHomeBanner', {
            images: response.data.data,
            text:response.data.title
        })
        return Promise.resolve(response);
      })
    },
    getPaymentMethods({ commit }) {
      userService.getPaymentMethods().then((response) => {
        commit('updatePaymentMethods', response.data.data)
        return Promise.resolve(response);
      })
    },
    getServices({ commit }) {
      userService.getServices().then((response) => {
        commit('updateServices', response.data)
        return Promise.resolve(response);
        
      })
    }
  },
  mutations: {
    increaseLoading(state) {
        state.loading++;
    },
    decreaseLoading(state) {
        state.loading--;
    },
    showError(state,message) {
      state.errorMessage = message
    },
    hideError(state) {
      state.errorMessage = null
    },
    showToast(state,message) {
      state.toastMessage = message
    },
    hideToast(state) {
      state.toastMessage = null
    },
    updateHomeBanner(state, data) {
      state.homeBannerImages = data.images
      state.homeBannerText = data.text
    },
    updatePaymentMethods(state, data) {
      state.paymentMethods = data
    },
     updateServices(state, data) {
       state.services = data.services
       state.serviceBannerText = data.banner_text
    }
  }
};