<template>
    <div class="two-d">
        <div class="content">
            <div class="p-3 border-bottom">
                <div class="actions d-flex justify-content-around my-2">
                    <router-link class="text-decoration-none col-4 px-3 text-center"
                        :to="{ name: '3d-betting-list', params: { type: '3d' } }">
                        <PrimaryButton class="mb-1 action text-danger py-3 mx-auto" text="danger"
                            style="width: 75% !important;">
                            <font-awesome-icon icon="fa-solid fa-rectangle-list" size="lg"></font-awesome-icon>
                        </PrimaryButton>
                        <p class="mb-0 fw-bold fs-13 text-danger ">မှတ်တမ်း</p>
                    </router-link>
                    <router-link class="text-decoration-none col-4 px-3 text-center" :to="{ name: '3d.winners' }">
                        <PrimaryButton class="mb-1 action text-danger py-3 mx-auto" text="danger"
                            style="width: 75% !important;">
                            <font-awesome-icon icon="fa-solid fa-trophy" size="lg"></font-awesome-icon>
                        </PrimaryButton>
                        <p class="mb-0 fw-bold fs-13 text-danger ">အနိုင်ရသူများ</p>
                    </router-link>
                </div>
            </div>

            <div class="p-3">
                <ul class="list-group list-group-flush">
                    <li v-for="(section, index ) in history" :key="index"
                        class="list-group-item d-flex align-items-center py-2">
                        <div class="mb-2">
                            <span class="fw-400 fs-12">ရက်စွဲ</span>
                            <p class="fw-600 fs-16 mb-0">{{ section.date }}</p>
                        </div>
                        <div class="ms-auto">
                            <span class="fw-400 fs-12">ဂဏန်း</span>
                            <p class="fw-600 fs-16 mb-0 text-danger">{{ section.number ?? '???' }}</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="action-box-wrapper">
            <div class="action-box">
                <div class="d-flex align-items-center">
                    <font-awesome-icon icon="fa-solid fa-money-bill" class="text-custom" />
                    <span class="fw-400 fs-14 mx-2">လက်ကျန်ငွေ</span>
                    <span class="fw-600 fs-14">{{ $helper.formattedNumber(currentUser.balance) }} Ks</span>
                </div>
                <PrimaryButton :disabled="!selectedSection" class="fw-700 fs-14 text-dark" @click="next()">ထိုးမည်
                </PrimaryButton>
            </div>
        </div>
    </div>
</template>
<script>
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import userService from '@/services/user.service';
export default {
    components: {
        PrimaryButton
    },
    data() {
        return {
            logined: this.$store.state.auth.status.loggedIn,
            currentUser: this.$store.state.auth.user,
            showBetModal: false,
            selectedSection: null,
            lottery: false,
            history: []
        }
    },
    mounted() {
        userService.get3DScheduleHistory().then(({ data }) => {
            this.history = data.data
        }).catch(response => {
            console.log(response)
        })

        userService.get3DScheduleList().then(({ data }) => {
            this.selectedSection = data.data
        }).catch(response => {
            console.log(response)
        })


    },
    methods: {
        chooseSection(section) {
            this.selectedSection = section
        },
        next() {
            this.$router.push({
                name: '3d-section',
                params: {
                    section: this.selectedSection
                }
            })
        }
    }
}
</script>
<style>
.two-d {
    padding-bottom: 14vh;
}


.two-d .content {
    border-radius: 1.2rem 1.2rem 0 0;
    background-color: #fff;
}

.two-d .action {
    /* width: 15% !important; */
    /* height: 12vw !important; */
}

.ending-time {
    height: 6vh;
    width: 75vw;
    right: 0;
    top: 0;
}

.two-d .section {
    background-color: #FFF0A4;
}

.two-d .section.active {
    color: #000;
    background-color: #FFC107 !important;
}
</style>